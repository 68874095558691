import {
  Avatar,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SubNavbar } from "../../Layout/components/SubNavbar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { useSubscription } from "../../../hooks/useSubscription";
import { useParams } from "react-router-dom";
import { ISubscribers } from "../../../models/subscription.model";
import Loader from "../../Loader/Loader";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { getStaticValue } from "../../../utils/StaticValues";
import SmallLoader from "../../Loader/SmallLoader";

enum Status {
  NOT_PAID = "NOT_PAID",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  PAID_BY_CASH = "PAID_BY_CASH",
}

const getStatusEnumValue = (status: string): Status => {
  switch (status) {
    case "NOT_PAID":
      return Status.NOT_PAID;
    case "EXPIRED":
      return Status.EXPIRED;
    case "CANCELLED":
      return Status.CANCELLED;
    case "PAID_BY_CASH":
      return Status.PAID_BY_CASH;
    default:
      return Status.NOT_PAID;
  }
};

const SubscriptionNotify = () => {
  const getBorderColor = (option: any) => {
    switch (option) {
      case "PAID":
        return "#7FC41B";
      case "PAID_BY_CASH":
        return "#7FC41B";
      case "NOT_PAID":
        return "#3C5AA0";
      case "EXPIRED":
        return "#DC8A8A";
      case "CANCELLED":
        return "#F44336";
      default:
        return "#3C5AA0";
    }
  };

  const statuses = [
    Status.NOT_PAID,
    Status.EXPIRED,
    Status.CANCELLED,
    Status.PAID_BY_CASH,
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const { subscriptionListByCommunityId, changePaymentStatusBySequenceId } =
    useSubscription();
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const { id } = useParams();
  // console.log(id, "id");

  const loadSubscription = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const subscription = await subscriptionListByCommunityId(
          selectedCommunityId
        );
        // console.log(subscription, "subscription");
        if (subscription) {
          setIsLoading(false);
          setSubscriptionList(subscription);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSubscription();
  }, [selectedCommunityId]);

  const [loadingStatus, setLoadingStatus] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const mobileViewHeight = 300;
  const desktopViewHeight = 330;

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  const filteredSubscription = subscriptionList.filter(
    (subscription: ISubscribers) => String(subscription._id) === String(id)
  );
  const [subscriptions, setSubscriptions] = useState<ISubscribers[]>([]);

  useEffect(() => {
    if (subscriptionList && subscriptionList.length > 0) {
      const filteredSubscription = subscriptionList.filter(
        (subscription: ISubscribers) => String(subscription._id) === String(id)
      );
      if (filteredSubscription && filteredSubscription.length > 0) {
        setSubscriptions(filteredSubscription);
      } else {
        console.warn(
          "No matching subscription found or subscriptionList is empty."
        );
      }
    }
  }, [subscriptionList, id]);

  const handleSelect = async (status: string, sequenceId: string) => {
    setLoadingStatus(true);
    try {
      const enumStatus = getStatusEnumValue(status);
      const updatedSubscription = await changePaymentStatusBySequenceId(
        sequenceId,
        enumStatus
      );
      if (updatedSubscription) {
        setSubscriptions((prevSubscriptions) => {
          const updated = prevSubscriptions.map((subscription) => ({
            ...subscription,
            sequences: subscription.sequences.map((sequence) =>
              sequence._id === sequenceId
                ? { ...sequence, status: updatedSubscription.data.status }
                : sequence
            ),
          }));
          return updated;
        });
      }
      handleClose();
    } catch (error) {
      console.error("Error changing payment status:", error);
    } finally {
      setLoadingStatus(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <SubNavbar>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            href="/subscribers"
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "inherit",
              fontFamily: "Inter",
            }}
          >
            Subscribers
          </Link>
          <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#3C5AA0",
              fontFamily: "Inter",
            }}
          >
            Subscriber Sequence
          </Typography>
        </Breadcrumbs>
      </SubNavbar>

      <div>
        {isLoading && <Loader />}
        {!isLoading && filteredSubscription?.length > 0
          ? filteredSubscription.map((subscription: any) => (
            <Paper
              key={subscription._id}
              sx={{ p: { xs: 3, md: 3 }, mb: 2, boxShadow: "none" }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                gap={5}
              >
                <Stack
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={2}
                >
                  <Avatar
                    src={subscription?.user?.avatar}
                    sx={{
                      height: "50px",
                      width: "50px",
                      fontSize: "11px",
                    }}
                    alt={`${subscription?.user?.firstName}`}
                  />
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Inter",
                    }}
                  >
                    {"  "}
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "Inter",
                      }}
                    >
                      {" "}
                      {subscription?.user?.firstName}
                    </Typography>
                    <br></br>
                    {subscription?.user?.emailId}
                    <br></br>
                    {subscription?.user?.phoneNumber}
                  </Typography>
                </Stack>
                <Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        pt: { xs: 1, md: 0 },
                        textTransform: "capitalize",
                      }}
                    >
                      Plan name : {subscription?.plan?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                    gap={3}
                    mt={2}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", md: "20px" },
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#3C5AA0",
                      }}
                    >
                      ₹
                      {subscription?.plan?.offerValue ||
                        subscription?.plan?.totalPlanValue}
                      /{subscription?.plan?.interval}
                      {subscription?.plan?.interval > "1"
                        ? `${getStaticValue(subscription?.plan?.duration)}s`
                        : getStaticValue(subscription?.plan?.duration)}
                    </Typography>
                    {subscription.active ? (
                      <Chip
                        icon={
                          <FiberManualRecordIcon
                            sx={{ color: "#7CB92E", fontSize: "15px" }}
                          />
                        }
                        label="Active"
                        sx={{
                          color: "#515151",
                          backgroundColor: "#7CB92E33",
                          "& .MuiChip-icon": {
                            color: "#7CB92E",
                          },
                        }}
                      />
                    ) : (
                      <Chip
                        icon={
                          <FiberManualRecordIcon
                            sx={{ color: "#7CB92E", fontSize: "15px" }}
                          />
                        }
                        label={subscription?.subscription_status === "ACTIVE" ? "Active" : "Inactive"}
                        sx={{
                          color: "#515151",
                          backgroundColor: subscription?.subscription_status === "ACTIVE" ? "#7CB92E33" : "#ffa87d1a",
                          "& .MuiChip-icon": {
                            color: subscription?.subscription_status === "ACTIVE" ? "#7CB92E" : "#ffa87d",
                          },
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          ))
          : !isLoading && <Typography>No Subscriptions Found</Typography>}
      </div>

      <Paper
        sx={{
          p: { xs: 1, md: 5 },
          mt: 2,
          height: { xs: "45vh", md: "60vh" },
          boxShadow: "none",
        }}
      >
        {filteredSubscription.map((subscription: any) => (
          <Stack display={"flex"} flexDirection={"row"}>
            <Stack>
              <Typography
                sx={{
                  fontSize: { xs: "15px", md: "18px" },
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#3C5AA0",
                  pt: { xs: 1, md: 0 },
                }}
              >
                Start Date{" "}
                {new Date(subscription?.startDate).toLocaleDateString()}
                {/* <Chip
                  label={
                    subscription.plan?.duration &&
                    (subscription?.plan?.interval > "1"
                      ? `${getStaticValue(subscription?.plan?.duration)}s`
                      : getStaticValue(subscription?.plan?.duration))
                  }
                  sx={{ color: "#515151", ml: 2 }}
                /> */}
              </Typography>
            </Stack>
            <Stack></Stack>
          </Stack>
        ))}
        <Stack>
          <SimpleBar style={{ height: `${height}px` }}>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {loadingStatus && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <SmallLoader />
                </Grid>
              )}

              {!loadingStatus &&
                subscriptions.map((subscription: ISubscribers) =>
                  subscription?.sequences.map((sequence, index) => (
                    <Grid
                      item
                      key={sequence._id}
                      xs={4}
                      sm={6}
                      md={2}
                      lg={1.5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "12px" },
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        {new Date(sequence?.startDate).toLocaleDateString()}
                      </Typography>

                      <Button
                        variant="outlined"
                        endIcon={<ArrowDropDownIcon />}
                        onClick={(e) => handleClick(e, sequence._id)}
                        disabled={sequence?.status === "PAID" || subscription?.subscription_status === "STOP"}
                        sx={{
                          backgroundColor: "#EEF7FD",
                          borderRadius: "30px",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#515151",
                          border: `1px solid ${getBorderColor(
                            sequence.status
                          )}`,
                          width: { xs: "100%", md: "90%" },
                          mt: 1,
                        }}
                      >
                        ₹
                        {subscription?.plan?.offerValue ||
                          subscription?.plan?.totalPlanValue}
                      </Button>

                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && selectedItemId === sequence._id
                        }
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          sx: {
                            borderRadius: "15px",
                            backgroundColor: "#E6F0F9",
                            padding: "0px 5px",
                            boxShadow: "none",
                            mt: 0.5,
                          },
                        }}
                      >
                        {statuses.map((status, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleSelect(status, sequence._id)}
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              padding: "3px 20px",
                              color: "#000000",
                              minHeight: "20px",
                              "&:hover": {
                                backgroundColor: "#D8EAF4",
                                borderRadius: "5px",
                              },
                            }}
                          >
                            {status}
                          </MenuItem>
                        ))}
                      </Menu>

                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "12px" },
                          color: "#333",
                          fontWeight: 400,
                          mt: 1,
                          textAlign: "center",
                        }}
                      >
                        {getStaticValue(sequence?.status)}

                      </Typography>
                    </Grid>
                  ))
                )}
            </Grid>
          </SimpleBar>
        </Stack>

        <Divider></Divider>
        {/* <Stack sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3C5AA0",
              textTransform: "capitalize",
              borderRadius: "5px",
              boxShadow: "none",
              width: "150px",

              "&:hover": {
                backgroundColor: "#3C5AA0",
                color: "white",
              },
            }}
            onClick={handleClickShow}
          >
            Notify
          </Button>
        </Stack> */}
      </Paper>
    </>
  );
};
export default SubscriptionNotify;
