import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

export const getPaymentRequestList = async (token: string, id: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/payment/${id}/requests`, {
        
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
   
      return response;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };

  export const addPaymentRequests = async (
    token: string,
    id: string,
    formatData: any
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/payment/${id}/requests`,
        formatData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 201) {
        return response;
      }
    } catch (err) {
      return { status: 500, data: [] };
    }
  };

  //Checkbox selected
  export const deleteAllPaymentRequest = async (token: string,formData:any) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/payment/delete-many-payment-requests`,
        {
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        return response; 
      }
      return response;
    } 
    
    catch (err) {
      console.error('Error:', err);
      return { status: 500, data: [] };
    }
  };

  export const deletePaymentRequestByUserId = async (token: string,communityId:string,id:string) => {
    try {
      const response = await axios.delete(`${BASE_URL}/payment/${communityId}/requests/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (err) {
      console.log("ERR :", err);
      return { status: 500, data: [] };
    }
  };
  export const updatePaymentRequest = async (
    token: string,
    communityId: string,
    userId:string,
    formData: any
  ) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/payment/${communityId}/requests/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return { status: 500, data: [] };
    }
  };

  