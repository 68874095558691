import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Box from "@mui/material/Box";
import {
  Grid,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IColumn } from "../../../models/table.model";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { SearchIcon1 } from "../../../assets/icons";
import { ContentWrapper } from "../../Layout/components/ContentWrapper";
import Loader from "../../Loader/Loader";
import { SubscriptionListTable } from "../../tables/SubscriptionListTable";
import { useSubscription } from "../../../hooks/useSubscription";
import { ISubscribers } from "../../../models/subscription.model";
import { NoSubscribers } from "../../AlternatePages/NoSubscribers";

const columns: IColumn[] = [
  { title: "User", dataKey: "user" },
  { title: "Plan Name", dataKey: "plan" },
  { title: "Amount", dataKey: "amount" },
  { title: "Billing Cycle", dataKey: "subscription" },
  { title: "Created", dataKey: "created" },
  { title: "Start Date", dataKey: "start" },
  { title: "Status", dataKey: "status" },
  { title: "Actions", dataKey: "actions" },
  { title: "", dataKey: "" },
];

export const SubscriptionList = () => {
  const navigate = useNavigate();
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;

  const { subscriptionListByCommunityId } = useSubscription();

  const [subscriberList, setSubscriberList] = useState<ISubscribers[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [planOptions, setPlanOptions] = useState<string[]>([]);

  const subscriptionList = async () => {
    try {
      setIsLoading(true);
      const subscribers = await subscriptionListByCommunityId(community_id);
      if (subscribers) {
        setSubscriberList(subscribers);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    subscriptionList();
  }, [community_id]);

  useEffect(() => {
    const uniquePlans = Array.from(
      new Set(subscriberList.map((subscriber) => subscriber?.plan?.name))
    );
    setPlanOptions(uniquePlans);
  }, [subscriberList]);

  const handleCreateNew = () => {
    navigate("/create-subscriptions");
  };

  const filteredUsers = subscriberList?.filter((subscriber: ISubscribers) => {
    const searchString = searchQuery.toLowerCase();
    const planMatch = selectedPlan
      ? subscriber?.plan?.name === selectedPlan
      : true;
    return (
      planMatch &&
      (subscriber?.community?.name.toLowerCase().includes(searchString) ||
        subscriber?.plan?.name.toLowerCase().includes(searchString) ||
        subscriber?.user?.firstName.toLowerCase().includes(searchString))
    );
  });

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={"column"} sx={{ height: "100%", p: 0 }}>
        {/* filters for large screens */}
        <Stack
          direction="row"
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Box component="form" noValidate autoComplete="off">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#3760A9",
                  fontFamily: "Inter",
                }}
              >
                Subscribers
              </Typography>
            </Box>
          </Stack>
          <Stack direction={"row-reverse"} flexGrow={1} spacing={2}>
            <Button
              variant="contained"
              startIcon={<AddCircleRoundedIcon />}
              onClick={handleCreateNew}
              sx={{
                textTransform: "capitalize",
                borderRadius: "30px",
                backgroundColor: "#3C5AA0",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  cursor: "pointer",
                  border: "none",
                },
              }}
            >
              Add Subscriber
            </Button>

            <TextField
              placeholder="Search by user"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1 }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "30px",
                  backgroundColor: "white",
                  height: "37px",
                  width: { xs: "150px", md: "200px" },
                  color: "#000000",
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <Autocomplete
              freeSolo
              options={planOptions}
              value={selectedPlan}
              onChange={(e, newValue) => setSelectedPlan(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Plan"
                  size="small"
                  sx={{
                    fontSize: "16px",
                  }}
                  InputProps={{
                    ...params.InputProps,

                    sx: {
                      borderRadius: "30px",
                      backgroundColor: "white",
                      height: "37px",
                      width: { xs: "150px", md: "200px" },
                      color: "#000000",
                      fontSize: "16px",
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "13px",
                    padding: "10px 16px",
                    "&[aria-selected='true']": {
                      backgroundColor: "#3760A9",
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#eceff1",
                    },
                  },
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          display={{ xs: "flex", md: "none", xl: "none", lg: "none" }}
          sx={{ mb: "5px" }}
        >
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#3760A9",
                    fontFamily: "Inter",
                    // ml: 3,
                  }}
                >
                  Subscribers
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleCreateNew}
                startIcon={<AddCircleRoundedIcon />}
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  backgroundColor: "#3C5AA0",
                }}
              >
                Add Subscriber
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                freeSolo
                options={planOptions}
                value={selectedPlan}
                onChange={(e, newValue) => setSelectedPlan(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Plan"
                    size="small"
                    sx={{
                      fontSize: "16px",
                    }}
                    InputProps={{
                      ...params.InputProps,

                      sx: {
                        borderRadius: "30px",
                        backgroundColor: "white",
                        height: "37px",

                        color: "#000000",
                        fontSize: "16px",
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "13px",
                      padding: "10px 16px",
                      "&[aria-selected='true']": {
                        backgroundColor: "#3760A9",
                        color: "#fff",
                      },
                      "&:hover": {
                        backgroundColor: "#eceff1",
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder="Search user"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "30px",
                    backgroundColor: "white",
                    height: "37px",

                    color: "#000000",
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack sx={{ pt: "5px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ContentWrapper>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {filteredUsers?.length === 0 ? (
                      <NoSubscribers />
                    ) : (
                      <SubscriptionListTable
                        data={filteredUsers || []}
                        columns={columns}
                        subscriptionList={subscriptionList}
                      />
                    )}
                  </>
                )}

              </ContentWrapper>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
