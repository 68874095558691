import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { PostsTable } from "../tables/PostsTable";
import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { EditIcon, DeleteIcon } from "../../assets/icons";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import EmojiPicker from "emoji-picker-react";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";

import { usePosts } from "../../hooks/usePosts";
import { IAction, IColumn } from "../../models/table.model";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ICommunity } from "../../models/communities.modal";
import { IUserInfo } from "../../models/user.model";
import { useSelector } from "react-redux";
import store from "../../store";
import { AddPost } from "../sub-components/AddPost";

import Loader from "../Loader/Loader";
import { NoPosts } from "../AlternatePages/noPosts";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";

export interface IPost {
  mapping: any[];
  updated: any;
  actions: IAction[];
  _id: string;
  whoCan: string;
  communities: ICommunity[];
  content: string;
  images: string[];
  createdAt: string;
  updatedAt: string;
  createdAtTime: string;
  updatedAtTime: string;
  hashTag: string;
  createdBy: IUserInfo;
  status: string;
  likedBy: IUserInfo[];
  sharedBy: IUserInfo[];
}
interface IPostList extends IPost {
  actions: IAction[];
}

export const Posts = () => {
  const state = store.getState();
  const [posts, setPosts] = useState<IPost[]>([]);
  const communitiesFromStore = useSelector(() => {
    return state?.communities ?? [];
  });
  const { deletePostById, deletePostByIds, isLoading, getPostList } =
    usePosts();
  const { communities } = communitiesFromStore; // getting community from store
  const [selectedPost, setSelectedPost] = useState<IPostList | null>(null);
  const [selectedCommunities, setSelectedCommunities] = useState<ICommunity[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // filters logic start
  const handleAutocompleteChange = (_: any, newValue: ICommunity[]) => {
    setSelectedCommunities(newValue);
    setInputVisible(false);
  };
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );
  const communitiesSuggestion = useSelector((state: any) => state?.communities);
  // const filteredSelectedCommunity = communitiesSuggestion?.communities?.filter(
  //   (community: any) =>
  //     community._id === selectedCommunity.selectedCommunity?.id
  // );
  let filteredSelectedCommunity: any = []; //= communitiesSuggestion?.communities?.filter(
  //   (community: any) =>
  //     community._id === selectedCommunity.selectedCommunity?.id
  // );
  if (Array.isArray(communitiesSuggestion?.communities)) {
    // Filter the array if it is not null or undefined
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?._id
    );
    // Use the filtered array here
  } else {
  }
  useEffect(() => {
    const fetchData = async () => {
      const postsData = await getPostList();
      if (postsData) setPosts(postsData);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  // const attachActions = (data: IPost[]): IPostList[] => {
  //   return data.map((d: IPost, i: number) => {
  //     return {
  //       ...d,

  //       actions: [
  //         { title: "Edit", action: "", tooltip: "Edit", icon: EditIcon },
  //         {
  //           title: "Delete",
  //           action: "",
  //           tooltip: "Delete",
  //           icon: DeleteIcon,
  //         },
  //       ],
  //     };
  //   });
  // };

  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);
  const attachActions = (data: IPost[]): IPostList[] => {
    return data.map((d: IPost, i: number) => {
      let additionalActions = [];

      if (d.createdBy && d.createdBy._id === loggedInUserData?.user?.id) {
        additionalActions.push(
          {
            title: "Edit",
            action: "",
            tooltip: "Edit",
            icon: EditIcon,
            styles: {
              cursor: "pointer",
              fontSize: "18px",
              marginRight: "15px",
              transition: "all 0.3s",
              // "&:hover": { transform: "scale3d(1.5, 1.5, 1.5)" },
              // Add other styles as needed
            },
          },
          {
            title: "Delete",
            action: "",
            tooltip: "Delete",
            icon: DeleteIcon,
            styles: {
              cursor: "pointer",
              fontSize: "18px",

              marginRight: "15px",
              transition: "all 0.3s",
              // "&:hover": { transform: "scale3d(1.5, 1.5, 1.5)" },
              // Add other styles as needed
            },
          }
        );
      } else {
        additionalActions.push(
          {
            title: "aaaa",
            action: "",
            tooltip: "Edit",
            icon: EditIcon,
            styles: {
              fontSize: "18px",
              color: "#898989",
              marginRight: "15px",
              transition: "all 0.3s",
              // "&:hover": { transform: "scale3d(1.5, 1.5, 1.5)" },
              // Add other styles as needed
            },
          },
          {
            title: "Delete",
            action: "",
            tooltip: "Delete",
            icon: DeleteIcon,
            styles: {
              fontSize: "18px",

              marginRight: "15px",
              transition: "all 0.3s",
              // "&:hover": { transform: "scale3d(1.5, 1.5, 1.5)" },
              // Add other styles as needed
            },
          }
        );
      }

      return {
        ...d,
        actions: [
          //  { title: "Delete", action: "", tooltip: "Delete", icon: DeleteIcon },
          ...additionalActions, // Include additional actions conditionally
        ],
      };
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleActionClick = (
    a: IAction,
    data: IPost,
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (a.title === "Edit") {
      setMode("Edit");
      setIsModalOpen(true);
      setSelectedPost(data);
      console.log(data);
    } else if (a.title === "Delete") {
      setSelectedPost(data);
      setAnchorEl(event?.currentTarget || null);
    }
  };
  const handleClose = () => {
    setSelectedPost(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleDeletePost = async () => {
    if (selectedPost) {
      try {
        // Call the delete API
        await deletePostById(selectedPost?._id);
        handleClose();
        setSelectedRows([]);
        // After successful deletion, update the posts list
        const updatedPostsData = await getPostList();

        if (updatedPostsData) {
          setPosts(updatedPostsData);
        }
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    }
  };
  const columns: IColumn[] = [
    { title: "Post", dataKey: "post" },
    { title: "Communities", dataKey: "communityType" },
    { title: "Posted by", dataKey: "postedBy" },
    { title: "Role Type", dataKey: "roleType" },
    //  { title: "Visibility", dataKey: "visibility" },
    { title: "Posted on", dataKey: "postedOn" },
    { title: "", dataKey: "" },
  ];
  // filters logic start
  const [openFilter, setOpenFilter] = useState(false);
  const [openxs, setOpenxs] = useState(false);
  const [postStatus, setPostStatus] = useState<string[]>([]);
  const [roleType, setRoleType] = useState<string[]>([]);
  const [communityType, setCommunityType] = useState<string[]>([]);

  const handleDoneButtonClick = () => {
    setOpenFilter(false);
    setOpenxs(false);
    // Close the select
  };

  // const handlePostStatusFilterChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value, checked } = event.target;
  //   console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
  //   console.log("Checkbox checked:", checked);
  //   if (checked) {
  //     setPostStatus((prevFilters) => [...prevFilters, value]);
  //   } else {
  //     setPostStatus((prevFilters) =>
  //       prevFilters.filter((filter: string) => filter !== value)
  //     );
  //   }
  // };
  const handleRoletypeFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
    console.log("Checkbox checked:", checked);
    if (checked) {
      setRoleType((prevFilters) => [...prevFilters, value]);
    } else {
      setRoleType((prevFilters) =>
        prevFilters.filter((filter: string) => filter !== value)
      );
    }
  };
  const handleCommunitytypeFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    // console.log("Checkbox value:", value);
    // console.log("Checkbox checked:", checked);
    if (checked) {
      setCommunityType((prevFilters) => [...prevFilters, value]);
    } else {
      setCommunityType((prevFilters) =>
        prevFilters.filter((filter: string) => filter !== value)
      );
    }
  };
  const filteredData = posts.filter((item: IPost) => {
    // Check if the community's status matches any of the selected status filters
    const statusMatch =
      postStatus.length === 0 || postStatus.includes(item.status);
    const roleMatch =
      roleType.length === 0 || roleType.includes(item?.createdBy?.role);
    const CommunityMatch =
      communityType.length === 0 ||
      communityType.includes(item?.communities[0]?.type);
    const communitySearch =
      selectedCommunities.length === 0 ||
      item.communities.some((community) =>
        selectedCommunities.some(
          (selectedCommunity) => selectedCommunity._id === community._id
        )
      );

    return statusMatch && roleMatch && CommunityMatch && communitySearch;
  });

  const handleClearButtonClick = () => {
    // Reset all filter states to their default values
    setPostStatus([]);
    setRoleType([]);
    setCommunityType([]);
  };
  //Model logic start
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState<"Add" | "Edit">("Add");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPost(null);
    setIsModalOpen(false);
    setMode("Add");
  };
  const sortedPosts = filteredData.sort((a, b) => {
    const dateTimeA = new Date(`${a.updatedAt} ${a.updatedAtTime}`);
    const dateTimeB = new Date(`${b.updatedAt} ${b.updatedAtTime}`);

    // Compare date and time together
    return dateTimeB.getTime() - dateTimeA.getTime();
  });

  const updatePosts = (newPost: IPost) => {
    setPosts((prevPosts: IPost[]) => [newPost, ...prevPosts]);
  };
  const updateExistingPost = (newPost: IPost) => {
    // console.log(newPost);
    setPosts((prevPosts) => [
      newPost,
      ...prevPosts.filter((post) => post._id !== newPost._id),
    ]);
  };
  const handleCheckboxClick = (postId: string) => {
    const selectedIndex = selectedRows.indexOf(postId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, postId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== postId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleDeleteClick = async () => {
    // console.log("Delete selected rows:", selectedRows);

    try {
      await deletePostByIds(selectedRows);

      // Filter out the deleted posts from the local state
      const updatedPosts = posts.filter(
        (post) => !selectedRows.includes(post._id)
      );
      setPosts(updatedPosts);
      setAnchorEl(null);
      // Clear selected rows after successful deletion
      setSelectedRows([]);
    } catch (error) {
      console.error("Error deleting posts:", error);
    }
  };
  const handleClearClick = () => {
    setSelectedRows([]); // Clear all selected rows
  };
  // eslint-disable-next-line
  const [inputVisible, setInputVisible] = useState(true);
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.post}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={"column"} spacing={1}>
        {/* filters for large screens */}
        <Stack
          direction="row"
          display={{ xs: "none", md: "flex", lg: "flex", xl: "flex" }}
          alignItems={"center"}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Box component="form" noValidate autoComplete="off">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#3760A9",
                  fontFamily: "Inter",
                }}
              >
                All Posts
              </Typography>
            </Box>
          </Stack>
          <Stack direction={"row-reverse"} flexGrow={1} spacing={2}>
            <Button
              variant="contained"
              startIcon={<AddCircleRoundedIcon />}
              onClick={handleOpenModal}
              sx={{
                textTransform: "capitalize",
                borderRadius: "30px",
                backgroundColor: "#3C5AA0",
                "&:hover": {
                  backgroundColor: "#3C5AA0", // Background color on hover
                  cursor: "pointer",
                  border: "none",
                },
              }}
            >
              Add Post
            </Button>

            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        // marginLeft: "18px",
                        // borderTop: "none",
                      },
                    },
                  }}
                  open={openFilter}
                  onOpen={() => setOpenFilter(true)}
                  onClose={() => setOpenFilter(false)}
                  sx={{
                    backgroundColor: "white",
                    // height:'5vh',
                    borderRadius: "30px",
                    "& .MuiSelect-icon": {
                      color: "#1A1A1A",
                    },
                    //  fontSize: "11px" ,font:'Roboto'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                      <Typography> Filters</Typography>
                    </InputAdornment>
                  }
                >
                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Community Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={communityType.includes("PUBLIC")}
                              onChange={handleCommunitytypeFilterChange}
                              value="PUBLIC"
                              size="small"
                            />
                          }
                          label="Public"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={communityType.includes("PRIVATE")}
                              onChange={handleCommunitytypeFilterChange}
                              value="PRIVATE"
                              size="small"
                            />
                          }
                          label="Private"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Role Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={roleType.includes("ADMIN")}
                              onChange={handleRoletypeFilterChange}
                              value="ADMIN"
                              size="small"
                            />
                          }
                          label="Admin"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={roleType.includes("USER")}
                              onChange={handleRoletypeFilterChange}
                              value="USER"
                              size="small"
                            />
                          }
                          label="Member"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  {openFilter && (
                    <Stack direction={"row-reverse"}>
                      <Button
                        onClick={handleClearButtonClick}
                        variant="outlined"
                        size="small"
                        sx={{
                          m: 1,
                          color: "#3C5AA0",
                          textTransform: "capitalize",
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDoneButtonClick}
                        size="small"
                        sx={{
                          m: 1,
                          backgroundColor: "#2952A2",
                          textTransform: "capitalize",
                        }}
                      >
                        Done
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Autocomplete
              sx={{
                width: "16vw",
                backgroundColor: "white",
                borderRadius: "30px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
                "& .MuiChip-root": {
                  borderRadius: "30px",
                },
              }}
              size="small"
              limitTags={1}
              ListboxProps={{ sx: { fontSize: "13px", font: "Roboto" } }}
              // ListboxProps={{ style: { maxHeight: 150 } }}
              id="community-autocomplete"
              options={communities ?? []}
              getOptionLabel={(option) =>
                `${option.title || option.name} +${option.counter}`
              }
              onChange={handleAutocompleteChange}
              multiple // Enable multi-select
              disableCloseOnSelect // Keep the dropdown open when selecting multiple options
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 4, padding: "3px", lineHeight: "1" }}
                    checked={selected}
                    size="small"
                  />
                  {option.title || option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose communities"
                  // InputLabelProps={{ shrink: false }}
                  placeholder="Type and search communities"
                  sx={{ width: "100%", maxHeight: "vh" }}
                />
              )}
              renderTags={(value, getTagProps) => (
                <>
                  {value.slice(0, 1).map((option, index) => (
                    <Chip
                      label={(option.title || option.name).slice(0, 6)}
                      {...getTagProps({ index })}
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  ))}
                  {value.length > 1 && (
                    <Chip
                      label={`+${value.length - 1}`}
                      disabled
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  )}
                </>
              )}
            />

          </Stack>
        </Stack>
        {/* for small screens  */}
        <Stack
          direction="row"
          display={{
            xs: "flex",
            sm: "flex",
            md: "none",
            xl: "none",
            lg: "none",
          }}
        >
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#3760A9",
                    fontFamily: "Inter",
                    // ml: 3,
                  }}
                >
                  All Posts
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddCircleRoundedIcon />}
                onClick={handleOpenModal}
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  backgroundColor: "#3C5AA0",

                  "&:hover": {
                    backgroundColor: "3C5AA0", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                Add Post
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                sx={{
                  width: "auto",
                  backgroundColor: "white",
                  borderRadius: "30px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "30px",
                  },
                  "& .MuiChip-root": {
                    borderRadius: "30px",
                  },
                }}
                size="small"
                limitTags={1}
                ListboxProps={{ sx: { fontSize: "13px", font: "Roboto" } }}
                // ListboxProps={{ style: { maxHeight: 150 } }}
                id="community-autocomplete"
                options={communities ?? []}
                getOptionLabel={(option) =>
                  `${option.title || option.name} +${option.counter}`
                }
                onChange={handleAutocompleteChange}
                multiple // Enable multi-select
                disableCloseOnSelect // Keep the dropdown open when selecting multiple options
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{
                        marginRight: 4,
                        padding: "3px",
                        lineHeight: "1",
                      }}
                      checked={selected}
                      size="small"
                    />
                    {option.title || option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose communities"
                    placeholder="Type and search communities"
                    InputLabelProps={{ style: { fontSize: "13px" } }}
                    sx={{ width: "100%", borderRadius: "30px" }}
                  />
                )}
                renderTags={(value, getTagProps) => (
                  <>
                    {value.slice(0, 1).map((option, index) => (
                      <Chip
                        label={(option.title || option.name).slice(0, 6)}
                        {...getTagProps({ index })}
                        sx={{ fontSize: "10px", height: "3vh" }}
                      />
                    ))}
                    {value.length > 1 && (
                      <Chip
                        label={`+${value.length - 1}`}
                        disabled
                        sx={{ fontSize: "10px", height: "3vh" }}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <FormControl size="small" fullWidth>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: "auto",
                          // borderTop: "none",
                        },
                      },
                    }}
                    open={openxs}
                    onOpen={() => setOpenxs(true)}
                    onClose={() => setOpenxs(false)}
                    sx={{
                      backgroundColor: "white",
                      // height:'5vh',
                      borderRadius: "30px",

                      "& .MuiSelect-icon": {
                        color: "#1A1A1A",
                      },
                      //  fontSize: "11px" ,font:'Roboto'
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon sx={{ fontSize: "20px" }} />
                        <Typography sx={{ fontSize: "13px" }}>
                          {" "}
                          Filters
                        </Typography>
                      </InputAdornment>
                    }
                  >
                    <Accordion disableGutters={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          Community Type
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: "0px" }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={communityType.includes("PUBLIC")}
                                onChange={handleCommunitytypeFilterChange}
                                value="PUBLIC"
                                size="small"
                              />
                            }
                            label="Public"
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={communityType.includes("PRIVATE")}
                                onChange={handleCommunitytypeFilterChange}
                                value="PRIVATE"
                                size="small"
                              />
                            }
                            label="Private"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion disableGutters={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          Role Type
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: "0px" }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={roleType.includes("ADMIN")}
                                onChange={handleRoletypeFilterChange}
                                value="ADMIN"
                                size="small"
                              />
                            }
                            label="Admin"
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={roleType.includes("USER")}
                                onChange={handleRoletypeFilterChange}
                                value="USER"
                                size="small"
                              />
                            }
                            label="Member"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>

                    {openxs && (
                      <Stack direction={"row-reverse"}>
                        <Button
                          onClick={handleClearButtonClick}
                          variant="outlined"
                          size="small"
                          sx={{
                            m: 1,
                            color: "#3C5AA0",
                            textTransform: "capitalize",
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleDoneButtonClick}
                          size="small"
                          sx={{
                            m: 1,
                            backgroundColor: "#2952A2",
                            textTransform: "capitalize",
                          }}
                        >
                          Done
                        </Button>
                      </Stack>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <AddPost
          open={isModalOpen}
          onClose={handleCloseModal}
          postHeder={mode === "Edit" ? "Edit Post" : "Add New Post"}
          editPostData={selectedPost || undefined}
          selectedCommunity={filteredSelectedCommunity}
          updatePosts={updatePosts}
          placeHolder=""
          updateExistingPost={updateExistingPost}
        />
        <Stack>
          <Paper
            elevation={0}
            sx={{
              flex: "1 1 100%",
              padding: "5px 20px",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {filteredData.length === 0 ? (
                  <PostsTable
                    data={attachActions([])}
                    onActionClick={handleActionClick}
                    columns={columns}
                    alternatePage={<NoPosts />}
                    onCheckboxClick={function (postId: string): void {
                      throw new Error("Function not implemented.");
                    }}
                    setSelectedRows={function (
                      value: React.SetStateAction<string[]>
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    selectedRows={[]}
                    onDeleteClick={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                    onClearClick={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                ) : (
                  <>
                    {/* {console.log("Filtered Data:", filteredData)} */}
                    <PostsTable
                      data={attachActions(sortedPosts)}
                      onActionClick={handleActionClick}
                      columns={columns}
                      alternatePage={""}
                      onCheckboxClick={handleCheckboxClick}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                      onDeleteClick={handleDeleteClick}
                      onClearClick={handleClearClick}
                    />
                  </>
                )}
              </>
            )}
          </Paper>
          {/* <Paper elevation={0}
            sx={{
              flex: "1 1 100%",
              padding: "5px 20px",
            }}>
  {filteredData.length === 0 ? (
    <NoPosts />
  ) : (
    <>
    
      <PostsTable
        data={attachActions(filteredData)}
        onActionClick={handleActionClick}
        columns={columns}
        alternatePage={""}
      />
    </>
  )}
</Paper>  */}
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Do you really want to delete this record? This process cannot be
          undone.
          {/* Delete Post */}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={handleDeletePost}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",

                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
