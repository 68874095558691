import { Card, CardMedia, Paper, Stack, Typography } from "@mui/material";

// Use lowercase for the function name
export const NoReports
    = () => {
        return (
            <>
                <Paper elevation={0}>
                    <Stack
                        spacing={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ paddingBottom: "10px", p: 2, }}
                    >
                        <Card elevation={0}>
                            <CardMedia
                                component="img"
                                alt="No posts"

                                image={require("./../../assets/icons/svg-icons/noPayments.png")}
                                sx={{
                                    objectFit: "cover",
                                    width: "100%", // Set the width to 100% to fill the container
                                    height: "30vh", // Set the height to 100% to fill the container
                                }}
                            />
                        </Card>
                        <Typography sx={{ color: '#777777', fontSize: { xs: '13px', md: '15px' }, fontWeight: '500', fontFamily: 'Inter' }}>No Reports </Typography>
                        {/* <Typography>No transactions yet</Typography> */}
                    </Stack>
                </Paper>
            </>
        );
    };
