import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SubNavbar } from "../Layout/components/SubNavbar";
// import { AutocompleteRenderInputParams, InputBase, styled } from "@mui/material";
// import  { Dayjs } from "dayjs";
// import TextField from "@mui/material/TextField";
import { Payments } from "../tables/Payments";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { usePayment } from "../../hooks/usePayment";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";

import { useNavigate } from "react-router-dom";

import {
  TotalRevenueIcon,
  SettledBalanceIcon,
  UnSettledBalanceIcon,
} from "../../assets/icons";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { IPaymentList } from "../../models/payment.model";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { NoPayment } from "../AlternatePages/noPaymentTrans";
import { usePlans } from "../../hooks/usePlans";

export const PaymentTransactions = () => {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-07"));
  const [selectedPlans, setSelectedPlans] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);

  const [paymentsList, setPaymentList] = useState<IPaymentList[]>([]);

  // console.log(paymentsList, "paymentsList");
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  const { getCommunityPaymentsById, isLoading } = usePayment();
  const { getCommunityPlansList } = usePlans();

  useEffect(() => {
    const fetchData = async () => {
      const communityPayments = await getCommunityPaymentsById(
        selectedCommunityId
      );
      if (communityPayments) setPaymentList(communityPayments);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);
  const loadPlans = async () => {
    try {
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);
        if (plans_data) {
          setPlans(plans_data);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
    }
  };
  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);
  // console.log(plans)
  const handleAutocompleteChange = (_: any, newValue: any[]) => {
    setSelectedPlans(newValue);
  };

  // eslint-disable-next-line
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean;
  }>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
  });

  // eslint-disable-next-line
  const [markedAsFilter, setMarkedAsFilter] = useState<string>("");

  // Default value when neither checkbox is checked
  // eslint-disable-next-line
  const [categoryFilter, setCategoryFilter] = useState("");
  const [paymentCycleFilter, setPaymentCycleFilter] = useState<string[]>([]);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);
  const [paymentModeFilter, setPaymentModeFilter] = useState<string[]>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<string[]>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  const navigate = useNavigate();
  const handleCreateNew = () => {
    navigate("/payments/requests");
  };

  //Filter Starts
  const handlePaymentCycleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    console.log("Checkbox value:", value);
    console.log("Checkbox checked:", checked);
    if (checked) {
      setPaymentCycleFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setPaymentCycleFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    // console.log("Status Filters:", paymentCycleFilter);
  };
  const handleModeFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    // console.log("Checkbox value:", value);
    // console.log("Checkbox checked:", checked);
    if (checked) {
      setPaymentModeFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setPaymentModeFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    // console.log("Status Filters:", paymentCycleFilter);
  };

  // const handlePaymentTypeFilterChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value, checked } = event.target;
  //   console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
  //   console.log("Checkbox checked:", checked);
  //   if (checked) {
  //     setPaymentTypeFilter((prevFilters) => [...prevFilters, value]);
  //   } else {
  //     setPaymentTypeFilter((prevFilters) =>
  //       prevFilters.filter((filter) => filter !== value)
  //     );
  //   }
  //   console.log("Status Filters:", paymentTypeFilter);
  // };

  const handlePaymentStatusFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    // console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
    // console.log("Checkbox checked:", checked);
    if (checked) {
      setPaymentStatusFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setPaymentStatusFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    // console.log("Status Filters:", paymentStatusFilter);
  };

  const filteredData = paymentsList?.filter((item: IPaymentList) => {
    const PaymentCycleMatch =
      paymentCycleFilter.length === 0 ||
      paymentCycleFilter.includes(item?.udf2?.offerEndsDuration);

    const PaymentPlanMatch =
      paymentTypeFilter.length === 0 ||
      paymentTypeFilter.includes(item?.udf2.name);

    const PaymentStatusMatch =
      paymentStatusFilter.length === 0 ||
      paymentStatusFilter.includes(item?.status);
    const PaymentModeMatch =
      paymentModeFilter.length === 0 || paymentModeFilter.includes(item?.mode);

    const communitySearch =
      selectedPlans.length === 0 ||
      selectedPlans.some((selected) => selected._id === item?.udf2?._id);

    // console.log(filteredData, "paymentModeFilter")

    // Return true only if both status and membership match the filters
    return (
      PaymentCycleMatch &&
      PaymentPlanMatch &&
      PaymentStatusMatch &&
      communitySearch &&
      PaymentModeMatch
    );
  });

  const sortedPayments = filteredData.sort((a, b) => {
    const dateB = new Date(b.addedon).getTime();
    const dateA = new Date(a.addedon).getTime();
    return dateB - dateA;
  });
  const totalAmount = filteredData.reduce((sum, record) => {
    const amount = parseFloat(record?.amount);
    return sum + amount;
  }, 0);
  //settledAmount
  const settledAmount = filteredData.reduce((sum, record) => {
    if (record.status === "success") {
      const amount = parseFloat(record?.amount);
      return sum + amount;
    }
    return sum;
  }, 0);
  const handleClearButtonClick = () => {
    // Reset all filter states to their default values
    setMarkedAsFilter("");
    setCategoryFilter("");
    setPaymentCycleFilter([]);
    setPaymentTypeFilter([]);
    setPaymentStatusFilter([]);
    setPaymentModeFilter([]);
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
    });
  };

  const [open, setOpen] = useState(false);
  const [openxs, setOpenxs] = useState(false);

  const handleDoneButtonClick = () => {
    setOpen(false); // Close the select
  };

  // Toolpit text for KYC icon
  const totalrevenue = `
   The combined amount of settled balance and unsettled balance since the beginning.
 `;

  const settledamount = `
 The platform fee amount, accumulated from all transactions made by paid community users, that has been credited to your account.
`;

  const unsettledamount = `
It represents the amount that has been received but is still pending to be credited to your account
`;

  return (
    <>
      <Stack direction={"column"} spacing={1} sx={{ height: "100%" }}>
        {/* for large screen  */}
        <Stack
          direction="row"
          display={{
            xs: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
            sm: "flex",
          }}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#3760A9",
                  fontFamily: "Inter",
                }}
              >
                Payment Transactions
              </Typography>
            </Grid>
          </Stack>
          <Stack direction={"row-reverse"} flexGrow={1} spacing={2}>
            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Button
                  variant="contained"
                  onClick={handleCreateNew}
                  startIcon={<AddCircleRoundedIcon />}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "30px",
                    backgroundColor: "#2952A2",
                    "&:hover": {
                      backgroundColor: "#2952A2", // Background color on hover
                      cursor: "pointer",
                      border: "none",
                    },
                  }}
                >
                  Payment Request
                </Button>
              </FormControl>
            </Box>
            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        // marginLeft: "18px",
                        // borderTop: "none",
                      },
                    },
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  sx={{
                    backgroundColor: "white",
                    // height:'5vh',
                    borderRadius: "30px",
                    "& .MuiSelect-icon": {
                      color: "#1A1A1A",
                    },
                    //  fontSize: "11px" ,font:'Roboto'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                      <Typography> Filters</Typography>
                    </InputAdornment>
                  }
                >
                  {/* <Accordion disableGutters={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Payment Cycle
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox1"
                              checked={paymentCycleFilter.includes("Year")}
                              onChange={handlePaymentCycleFilterChange}
                              value="Year"
                              size="small"
                            />
                          }
                          label="Year"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes(
                                "HALF_YEARLY"
                              )}
                              onChange={handlePaymentCycleFilterChange}
                              value="HALF_YEARLY"
                              size="small"
                            />
                          }
                          label="Half-Yearly"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes("QUARTERLY")}
                              onChange={handlePaymentCycleFilterChange}
                              value="QUARTERLY"
                              size="small"
                            />
                          }
                          label="Quarterly"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes("MONTHLY")}
                              onChange={handlePaymentCycleFilterChange}
                              value="MONTHLY"
                              size="small"
                            />
                          }
                          label="Month"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes("Weeks")}
                              onChange={handlePaymentCycleFilterChange}
                              value="Weeks"
                              size="small"
                            />
                          }
                          label="Week"
                        />

                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes("DAILY")}
                              onChange={handlePaymentCycleFilterChange}
                              value="DAILY"
                              size="small"
                            />
                          }
                          label="Day"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              name="checkbox2"
                              checked={paymentCycleFilter.includes("ONE_TIME")}
                              onChange={handlePaymentCycleFilterChange}
                              value="ONE_TIME"
                              size="small"
                            />
                          }
                          label="One-Time"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion> */}
                  {/* <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Payment Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentTypeFilter.includes("Standard")}
                              onChange={handlePaymentTypeFilterChange}
                              value="Standard"
                              size="small"
                            />
                          }
                          label="Standard"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentTypeFilter.includes("Economy")}
                              onChange={handlePaymentTypeFilterChange}
                              value="Economy"
                              size="small"
                            />
                          }
                          label="Economy"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentTypeFilter.includes("Preminum")}
                              onChange={handlePaymentTypeFilterChange}
                              value="Preminum"
                              size="small"
                            />
                          }
                          label="Preminum"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion> */}
                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Payment Mode
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("CARDS")}
                              onChange={handleModeFilterChange}
                              value="CARDS"
                              size="small"
                            />
                          }
                          label="Cards"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("NB")}
                              onChange={handleModeFilterChange}
                              value="NB"
                              size="small"
                            />
                          }
                          label="NetBanking"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("WALLET")}
                              onChange={handleModeFilterChange}
                              value="WALLET"
                              size="small"
                            />
                          }
                          label="Wallet"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("EMI")}
                              onChange={handleModeFilterChange}
                              value="EMI"
                              size="small"
                            />
                          }
                          label="EMI"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("UPI")}
                              onChange={handleModeFilterChange}
                              value="UPI"
                              size="small"
                            />
                          }
                          label="UPI"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentModeFilter.includes("BNPL")}
                              onChange={handleModeFilterChange}
                              value="BNPL"
                              size="small"
                            />
                          }
                          label="BNPL"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Payment Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes("PAID_BY_CASH")}
                              onChange={handlePaymentStatusFilterChange}
                              value="PAID_BY_CASH"
                              size="small"
                            />
                          }
                          label="Paid By Cash"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes("SUCCESS")}
                              onChange={handlePaymentStatusFilterChange}
                              value="SUCCESS"
                              size="small"
                            />
                          }
                          label="Success"
                        />

                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentStatusFilter?.includes("DROPPED")}
                              onChange={handlePaymentStatusFilterChange}
                              value="DROPPED"
                              size="small"
                            />
                          }
                          label="Dropped"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentStatusFilter?.includes(
                                "USERCANCELLED"
                              )}
                              onChange={handlePaymentStatusFilterChange}
                              value="USERCANCELLED"
                              size="small"
                            />
                          }
                          label="Cancelled"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={paymentStatusFilter?.includes("FAILED")}
                              onChange={handlePaymentStatusFilterChange}
                              value="FAILED"
                              size="small"
                            />
                          }
                          label="Failed"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {open && (
                    <Stack direction={"row-reverse"}>
                      <Button
                        onClick={handleClearButtonClick}
                        variant="outlined"
                        size="small"
                        sx={{
                          m: 1,
                          color: "#3C5AA0",
                          textTransform: "capitalize",
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDoneButtonClick}
                        size="small"
                        sx={{
                          m: 1,
                          backgroundColor: "#2952A2",
                          textTransform: "capitalize",
                        }}
                      >
                        Done
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Autocomplete
              sx={{
                width: "16vw",
                backgroundColor: "white",
                borderRadius: "30px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
                "& .MuiChip-root": {
                  borderRadius: "30px",
                },
              }}
              size="small"
              limitTags={1}
              ListboxProps={{
                sx: { fontSize: "13px", font: "Roboto", borderRadius: "30px" },
              }}
              id="community-autocomplete"
              options={plans ?? []}
              getOptionLabel={(option) =>
                `${option?.title || option?.name} +${option?.counter}`
              }
              onChange={handleAutocompleteChange}
              multiple
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 4, padding: "3px", lineHeight: "1" }}
                    checked={selected}
                    size="small"
                  />
                  {option?.title || option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Plan"
                  placeholder="Type and search Plan"
                  sx={{ width: "100%", borderRadius: "30px" }}
                />
              )}
              renderTags={(value, getTagProps) => (
                <>
                  {value?.slice(0, 1).map((option, index) => (
                    <Chip
                      label={(option?.title || option?.name).slice(0, 6)}
                      {...getTagProps({ index })}
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  ))}
                  {value?.length > 1 && (
                    <Chip
                      label={`+${value?.length - 1}`}
                      disabled
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  )}
                </>
              )}
            />


            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                style={{ border: '1px solid gray', backgroundColor: 'white', borderRadius: '5px' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Search> */}
          </Stack>
        </Stack>

        {/* for small screen */}
        <Stack
          direction="row"
          display={{
            xs: "flex",
            md: "none",
            lg: "none",
            xl: "none",
            sm: "none",
          }}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#3760A9",
                    fontFamily: "Inter",
                  }}
                >
                  Transactions
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box component="form" noValidate autoComplete="off">
                  <FormControl size="small" fullWidth>
                    <Button
                      variant="contained"
                      onClick={handleCreateNew}
                      startIcon={<AddCircleRoundedIcon />}
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "30px",
                        backgroundColor: "#2952A2",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#2952A2", // Background color on hover
                          cursor: "pointer",
                          border: "none",
                        },
                      }}
                    >
                      Payment Request
                    </Button>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  sx={{
                    width: "auto",
                    backgroundColor: "white",
                    borderRadius: "30px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "30px",
                    },
                    "& .MuiChip-root": {
                      borderRadius: "30px",
                    },
                  }}
                  size="small"
                  limitTags={1}
                  ListboxProps={{ sx: { fontSize: "13px", font: "Roboto" } }}
                  // ListboxProps={{ style: { maxHeight: 150 } }}
                  id="community-autocomplete"
                  options={plans ?? []}
                  getOptionLabel={(option) =>
                    `${option.title || option.name} +${option.counter}`
                  }
                  onChange={handleAutocompleteChange}
                  multiple // Enable multi-select
                  disableCloseOnSelect // Keep the dropdown open when selecting multiple options
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{
                          marginRight: 4,
                          padding: "3px",
                          lineHeight: "1",
                        }}
                        checked={selected}
                        size="small"
                      />
                      {option.title || option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Plan"
                      // InputLabelProps={{ shrink: false }}
                      placeholder="Type and search Plans"
                      sx={{ width: "100%", borderRadius: "30px" }}
                    />
                  )}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.slice(0, 1).map((option, index) => (
                        <Chip
                          label={(option?.title || option?.name).slice(0, 1)}
                          {...getTagProps({ index })}
                          sx={{ fontSize: "10px", height: "3vh", width: "20%" }}
                        />
                      ))}
                      {value.length > 1 && (
                        <Chip
                          label={`+${value.length - 1}`}
                          disabled
                          sx={{ fontSize: "10px", height: "3vh" }}
                        />
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Box component="form" noValidate autoComplete="off">
                  <FormControl size="small" fullWidth>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            // borderTop: "none",
                          },
                        },
                      }}
                      open={openxs}
                      onOpen={() => setOpenxs(true)}
                      onClose={() => setOpenxs(false)}
                      sx={{
                        backgroundColor: "white",
                        // height:'5vh',
                        borderRadius: "30px",
                        "& .MuiSelect-icon": {
                          color: "#1A1A1A",
                        },
                        //  fontSize: "11px" ,font:'Roboto'
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FilterListIcon />
                          <Typography> Filters</Typography>
                        </InputAdornment>
                      }
                    >
                      {/* <Accordion disableGutters={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            Payment Cycle
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox1"
                                  checked={paymentCycleFilter.includes(
                                    "Year"
                                  )}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="Year"
                                  size="small"
                                />
                              }
                              label="Year"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes(
                                    "HALF_YEARLY"
                                  )}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="HALF_YEARLY"
                                  size="small"
                                />
                              }
                              label="Half-Yearly"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes(
                                    "QUARTERLY"
                                  )}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="QUARTERLY"
                                  size="small"
                                />
                              }
                              label="Quarterly"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes(
                                    "MONTHLY"
                                  )}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="MONTHLY"
                                  size="small"
                                />
                              }
                              label="Month"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes("Weeks")}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="Weeks"
                                  size="small"
                                />
                              }
                              label="Week"
                            />

                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes("DAILY")}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="DAILY"
                                  size="small"
                                />
                              }
                              label="Day"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  name="checkbox2"
                                  checked={paymentCycleFilter.includes(
                                    "ONE_TIME"
                                  )}
                                  onChange={handlePaymentCycleFilterChange}
                                  value="ONE_TIME"
                                  size="small"
                                />
                              }
                              label="One-Time"
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion> */}
                      {/* <Accordion disableGutters={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            Payment Type
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: "0px" }}>
                          <Stack>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentTypeFilter.includes(
                                    "Standard"
                                  )}
                                  onChange={handlePaymentTypeFilterChange}
                                  value="Standard"
                                  size="small"
                                />
                              }
                              label="Standard"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentTypeFilter.includes(
                                    "Economy"
                                  )}
                                  onChange={handlePaymentTypeFilterChange}
                                  value="Economy"
                                  size="small"
                                />
                              }
                              label="Economy"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentTypeFilter.includes(
                                    "Preminum"
                                  )}
                                  onChange={handlePaymentTypeFilterChange}
                                  value="Preminum"
                                  size="small"
                                />
                              }
                              label="Preminum"
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion> */}
                      <Accordion disableGutters={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            Payment Mode
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: "0px" }}>
                          <Stack>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("CARDS")}
                                  onChange={handleModeFilterChange}
                                  value="CARDS"
                                  size="small"
                                />
                              }
                              label="Cards"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("NB")}
                                  onChange={handleModeFilterChange}
                                  value="NB"
                                  size="small"
                                />
                              }
                              label="NetBanking"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("WALLET")}
                                  onChange={handleModeFilterChange}
                                  value="WALLET"
                                  size="small"
                                />
                              }
                              label="Wallet"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("EMI")}
                                  onChange={handleModeFilterChange}
                                  value="EMI"
                                  size="small"
                                />
                              }
                              label="EMI"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("UPI")}
                                  onChange={handleModeFilterChange}
                                  value="UPI"
                                  size="small"
                                />
                              }
                              label="UPI"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentModeFilter.includes("BNPL")}
                                  onChange={handleModeFilterChange}
                                  value="BNPL"
                                  size="small"
                                />
                              }
                              label="BNPL"
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion disableGutters={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                            Payment Status
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: "0px" }}>
                          <Stack>
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentStatusFilter?.includes(
                                    "SUCCESS"
                                  )}
                                  onChange={handlePaymentStatusFilterChange}
                                  value="SUCCESS"
                                  size="small"
                                />
                              }
                              label="Success"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentStatusFilter?.includes(
                                    "DROPPED"
                                  )}
                                  onChange={handlePaymentStatusFilterChange}
                                  value="DROPPED"
                                  size="small"
                                />
                              }
                              label="Dropped"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentStatusFilter?.includes(
                                    "USERCANCELLED"
                                  )}
                                  onChange={handlePaymentStatusFilterChange}
                                  value="USERCANCELLED"
                                  size="small"
                                />
                              }
                              label="Cancelled"
                            />
                            <FormControlLabel
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              control={
                                <Checkbox
                                  checked={paymentStatusFilter?.includes(
                                    "FAILED"
                                  )}
                                  onChange={handlePaymentStatusFilterChange}
                                  value="FAILED"
                                  size="small"
                                />
                              }
                              label="Failed"
                            />
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      {openxs && (
                        <Stack
                          direction={"row-reverse"}
                          justifyContent={"space-evenly"}
                        >
                          <Button
                            onClick={handleClearButtonClick}
                            variant="outlined"
                            size="small"
                            sx={{
                              m: 1,
                              color: "#3C5AA0",
                              textTransform: "capitalize",
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleDoneButtonClick}
                            size="small"
                            sx={{
                              m: 1,
                              backgroundColor: "#2952A2",
                              textTransform: "capitalize",
                            }}
                          >
                            Done
                          </Button>
                        </Stack>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
      {/* Reports Boxes */}

      <Stack>
        <Grid container spacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
                paddingBottom: "10px",
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TotalRevenueIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />
                  <Tooltip
                    title={totalrevenue}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Total Revenue
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    ₹{totalAmount.toFixed(2)}
                  </Typography>

                  {/* <Chip  label="6%" variant="outlined" sx={{color:'white', fontSize:'13px',fontWeight:'normal', display:{xs:'none', md:'block'},width:'50px', height:'20px'}} /> */}
                </Stack>
                {/* <Chip  label="6%" variant="outlined" sx={{color:'white', fontSize:'10px',fontWeight:'normal', display:{xs:'block', md:'none',}, width:'40px', height:'15px'}} /> */}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SettledBalanceIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />

                  <Tooltip
                    title={settledamount}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Settled Amount
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    ₹{settledAmount.toFixed(2)}
                  </Typography>

                  {/* <Chip label="16%" variant="outlined" sx={{ color: 'white', fontSize: '13px', fontWeight: 'normal', display: { xs: 'none', md: 'block' }, width: '50px', height: '20px' }} /> */}
                </Stack>
                {/* <Chip label="16%" variant="outlined" sx={{ color: 'white', fontSize: '10px', fontWeight: 'normal', display: { xs: 'block', md: 'none', }, width: '40px', height: '15px' }} /> */}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <UnSettledBalanceIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />

                  <Tooltip
                    title={unsettledamount}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>

                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Unsettled Balance
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    ₹{(totalAmount - settledAmount).toFixed(2)}
                  </Typography>

                  {/* <Chip label="10%" variant="outlined" sx={{ color: 'white', fontSize: '13px', fontWeight: 'normal', display: { xs: 'none', md: 'block' }, width: '50px', height: '20px' }} /> */}
                </Stack>
                {/* <Chip label="10%" variant="outlined" sx={{ color: 'white', fontSize: '10px', fontWeight: 'normal', display: { xs: 'block', md: 'none', }, width: '40px', height: '15px' }} /> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      {/* <Stack direction={"column"} spacing={1}>
        <SubNavbar >
          <Stack direction={"row"} spacing={5}>
          </Stack>
        </SubNavbar>


        <Stack  sx={{ width: "100%" }} >
          <Paper
            elevation={0}
            sx={{
              flex: "1 1 100%",
              padding: "5px 20px",
            }}
          >
            <Payments data={filteredData}
            />
          </Paper>
        </Stack>

      </Stack> */}
      <Stack direction={"column"} spacing={1}>
        <SubNavbar>
          <Stack direction={"row"} spacing={5}></Stack>
        </SubNavbar>
        <Stack>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {filteredData?.length === 0 ? (
                <Payments alternatePage={<NoPayment />} data={undefined} />
              ) : (
                <>
                  <Payments alternatePage={""} data={sortedPayments} />
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
// function setPaymetCycleFilter(arg0: string) {
//   throw new Error("Function not implemented.");
// }
