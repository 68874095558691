import { useContext, useState } from "react";
import { IEditUser,  IUser, IUser1 } from "../models/user.model";
import { addUsers, deleteInvite,leaveCommunityUser, getInvitations, getUser, getUsers, updateUser, existUserCheck } from "../services/users.service";
import { useSnackbar } from "notistack";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
// import store from "../store";

export const useUsers = () => {
  const { getAccessToken} = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser>();
  //const [addUser,setAddUser]= useState<INewUser>();
  const [invitations, setInvitations] = useState<IUser1[]>([]);

  const getUsersList = async (communityId:string) => {
    setIsLoading(true);
    const response = await getUsers(getAccessToken(),communityId);
    if (response.status === 200) {
      // setUsers(response.data);
      setUsers((prevUsers) => [...prevUsers, ...response.data]);
      return response.data
    } else {
      enqueueSnackbar(" Failed to fetch users", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
  };
  
  const addUsersList = async (usersToAdd: FormData) => {
    try {
      const response = await addUsers(getAccessToken(), usersToAdd);
      //console.log("FormData:", usersToAdd);
      if (response.status === 200) {        
       // setAddUser(response.data);
        enqueueSnackbar("Invite sent", { variant: "success", autoHideDuration: 3000 });
      }else {
        enqueueSnackbar("Fetching users failed", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
      
    } catch (error) {
      enqueueSnackbar("Couldn’t add user", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };


  const editUsers = async (id:string,user: IEditUser,avatar:File|null) => {
    setIsLoading(true);
   
    const response = await updateUser(getAccessToken(),id,user,avatar);
    if (response.status === 200) {
    setUser(response.data);
    return response.data??'';
    } else {
      enqueueSnackbar("Profile update failed", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
  }

  const getInviteList = async (id:string) => {
    setIsLoading(true);
    const response = await getInvitations(getAccessToken(),id);
    if (response.status === 200) {
      setInvitations(response.data.data);
      return response.data
    } else {
      enqueueSnackbar("Failed to fetch invite list", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
  };

  const deleteInviteById = async (communityId:string) => {
    try {
      const response = await deleteInvite(getAccessToken(), communityId);
      if (response.status === 200) {
        setInvitations(response.data)
         enqueueSnackbar("Invite deleted", { variant: "success",autoHideDuration: 3000 });
      } else {
         enqueueSnackbar("Couldn’t delete invite", { variant: "error", autoHideDuration: 3000 });
      } return response; 
    } catch (error) {
      enqueueSnackbar("Couldn’t delete invite", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const leaveCommunityUserById = async(id:string) => {
    try {
      const response = await leaveCommunityUser(getAccessToken(),id);
      console.log(response);
      
      if(response.status === 200){
        setUser(response.data)
        enqueueSnackbar("User deleted from this community successfully", { variant: "success", autoHideDuration: 3000 });
      }else{
        enqueueSnackbar("Invalid User delete from this community", { variant: "error", autoHideDuration: 3000 });
      }
      return response;

    }catch(error){

    }
  }

  const loadUser = async(id: string) => {
    setIsLoading(true);
    const response = await getUser(getAccessToken(),id);
    if (response.status === 200) {
    setUser(response.data);
    return response.data??'';
    } else {
      enqueueSnackbar("Failed to fetch user details", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
    
  }


  // const checkExistUser = async(phoneNumber, token, id) => {
  //   setIsLoading(true);
  //   const response = await existUserCheck(getAccessToken(),id);
  //   if (response.status === 200) {
  //   setUser(response.data);
  //   return response.data??'';
  //   } else {
  //     enqueueSnackbar("Failed to fetch user details", { variant: "error", autoHideDuration: 3000 });
  //   }
  //   setIsLoading(false);
    
  // }
  
  const isUserExists = async (phoneNumber:string) => {
      const response = await existUserCheck(getAccessToken(), phoneNumber);
      if (response.status === 200) {
        return true;
      } else if (response.status === 302) {
        return false;
      } else {
        // enqueueSnackbar("", { variant: "error" });
      }
    };
  

  return {
    isLoading,
    users,
    user,
    addUsersList,
    editUsers,
    loadUser,
    getUsersList,
    getInviteList,
    deleteInviteById,
    leaveCommunityUserById,
    isUserExists,
    invitations,
  };
};
