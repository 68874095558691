// import React from "react";
import { PaymentTransactions } from "./PaymentTransaction";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";
// import DataTables from "../sub-components/Table";
// import { PaymentDetails } from "./PaymentDetails";

export const Payments = () => {
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.payments}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <div>
        <PaymentTransactions />
        {/* <DataTables /> */}
      </div>
    </>
  );
};
