import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { generateQrCode } from "../services/qrcode.service";

export const useQrCode = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getQrCode = async (id: string, url: string) => {
    setIsLoading(true);
    const response = await generateQrCode(getAccessToken(), id, url);
    setIsLoading(false);
    if (response.status === 200) {
      return response;
    } else if (response.status === 302) {
      return response;
    } else {
      enqueueSnackbar("Fetching Qr code failed", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return {
    isLoading,
    getQrCode,
  };
};
