import * as React from "react";
import { Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";
import JoinedCommunities from "./SliderCommunities/JoinedCommunities";
import InvitesCommunities from "./SliderCommunities/InvitesCommunities";
import TrendingCommunities from "./SliderCommunities/TrendingCommunities";
import AdministeredCommunities from "./SliderCommunities/AdministeredCommunities";
import { useEffect, useState } from "react";
import { ICommunity } from "../../models/communities.modal";
import { useCommunity } from "../../hooks/useCommunity";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
// import Loader from "../Loader/Loader";
import SmallLoader from "../Loader/SmallLoader";
import { metaConfig } from "../../utils/constants";
// import AdminCommunitiesGird from "./GirdViewCommunities/AdminCommunitiesGird";
// import SearchHeader from "./Layout/SearchHearder";

const ExploreCommunities = (communitytitle: any) => {
  const { getCommunitiesList } = useCommunity();

  const [communities, setCommunities] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState<ICommunity[]>([]);
  const [searchQuery,] = useState<string>("");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChanges =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCommunitiesList = async () => {
    try {
      setIsLoading(true);
      if (!communities.length) {
        const communitiesData = await getCommunitiesList();
        setCommunities(communitiesData);
      }

      // search
      const filteredUser = communities?.filter((community: ICommunity) =>
        `${community.title} ${community.fullAddress}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filteredUser);
    } catch (e) {
      console.log("error occurred....", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCommunitiesList();
  }, [communities?.length]);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.exploreCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Stack>
              <InvitesCommunities />
            </Stack>


            {/* <Stack>
              {isLoading ? (
                <SmallLoader />
              ) : (
                <AdministeredCommunities data={filteredUsers} />
              )}
            </Stack>
            <Stack>
              <JoinedCommunities />
            </Stack> */}

            <Stack
              sx={{ p: 0.5, borderRadius: "10px", backgroundColor: "#ffffff" }}
            >
              <Accordion
                defaultExpanded
                sx={{ borderRadius: "10px", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "18px" },
                      fontWeight: 700,
                      textDecoration: "none",
                      color: "black",
                      // marginLeft: 10,
                    }}
                  >
                    My Communities
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    pl: 1,
                    pr: 1,
                    backgroundColor: "#f4f4f4",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Stack>
                    {isLoading ? (
                      <SmallLoader />
                    ) : (
                      <AdministeredCommunities data={filteredUsers} />
                    )}
                  </Stack>
                  <Stack>
                    {isLoading ? (
                      <SmallLoader />
                    ) : (
                      <JoinedCommunities />
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>
            <Stack sx={{pb:{xs:2, md:1}}}>
              <TrendingCommunities />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ExploreCommunities;
