import { useEffect, useRef, useState } from 'react';
import jwt_decode from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { loginUser, logoutUser } from '../store/slices/StoreSlice';
import store from '../store';
import {
  loadCommunities,
  loadSelectedCommunity,
  setAdminCommunities,
  setJoinedCommunities,
  unloadSelectedCommunity,
  unloadCommunities,
} from '../store/slices/CommunitySlice';
import { communityById, getCommunities } from '../services/communities.service';
import { ICommunity, IMemberCommunity } from '../models/communities.modal';
import { useDispatch } from 'react-redux';
import { getDeviceToken } from '../services/pushNotification/pushNotification.service';

const setTokens = ({ accessToken, refreshToken }: any) => {
  if (
    accessToken &&
    accessToken !== null &&
    accessToken !== undefined &&
    accessToken !== ''
  ) {
    localStorage.setItem('access-token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
  }
};

const setCommunityTols = (communityId: any) => {
  localStorage.setItem('community', communityId);
};
const getTokens = () => {
  return {
    accessToken: localStorage.getItem('access-token'),
    refreshToken: localStorage.getItem('refresh-token'),
  };
};
const removeTokens = () => {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
  localStorage.removeItem('community');
};

const purgeStoredState = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const useAuth = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  // eslint-disable-next-line
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [community] = useState<string | null>(null);
  const intervalId = useRef<any>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams(window.location.search);
        const token = params?.get('token');
        const userCommunId = params?.get('communityId');

        if (params && token && userCommunId) {
          localStorage.setItem('access-token', token);
          localStorage.setItem('refresh-token', token);

          const userToken = await axios.get(
            `${BASE_URL}/auth/get-user-by-token`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setTokens(token);
          setIsAuthenticated(true);
          setAccessToken(token);
          setUser(userToken.data);
          setCommunityTols(userToken?.data.community);
          store.dispatch(loginUser(userToken.data)); //set the usertoken data to the store

          //call the api using user selected admin communityId
          const res = await communityById(token, userCommunId);
          if (res.status === 200) {
            store.dispatch(loadSelectedCommunity(res.data)); //update the store with communty details
          }

          const communities = await getCommunities(token); //set admin communities to store
          if (communities.status === 200) {
            store.dispatch(loadCommunities(communities.data));
          }

          if (userCommunId && userCommunId.length > 0) {
            const res = await communityById(token, userCommunId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
            }
            res.data = { ...res.data, community: userCommunId };

            localStorage.setItem('communityId', userCommunId);
          }

          window.location.href = '/home';
        } else if (params && token) {
          localStorage.setItem('access-token', token);
          localStorage.setItem('refresh-token', token);
          const userToken = await axios.get(
            `${BASE_URL}/auth/get-user-by-token`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setTokens(token);
          setIsAuthenticated(true);
          setAccessToken(token);
          setUser(userToken.data);
          store.dispatch(loginUser(userToken.data)); //set the usertoken data to the store
          window.location.href = '/explore-create-community';
          console.log(userToken, 'userToken');
        }
      } catch (error) {
        console.error('Error processing URL parameters:', error);
        setLoading(false);
      }
    };
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = (token: string) => {
    const decoded: { exp: number } = jwt_decode(token);
    const remainingTime = decoded.exp - ((Date.now() / 1000) | 0);
    if (remainingTime < 100) {
      clearInterval(intervalId.current);
      getNewToken();
    } else {
      if (!isAuthenticated) {
        setIsAuthenticated(true);
        setLoading(false);
      }
    }
  };

  const getNewToken = () => {
    logout();
    setLoading(false);
  };

  // GET TOKENS FROM LOCAL STORAGE
  useEffect(() => {
    const { accessToken, refreshToken } = getTokens();
    if (accessToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      verifyToken(accessToken);
    } else {
      getNewToken();
    }
  });

  useEffect(() => {
    if (accessToken) {
      clearInterval(intervalId.current);
      intervalId.current = setInterval(() => {
        verifyToken(accessToken);
      }, 1000 * 60);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const getAccessToken = () => {
    return accessToken ?? '';
  };

  const getCommunity = () => {
    return community ?? localStorage.getItem('community') ?? '';
  };

  const login = async (cred: any) => {
    // setLoading(true);
    try {
      const response = (await axios.post(
        `${BASE_URL}/login`,
        {
          ...cred,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;

      if (response.status === 200) {
        const { user, adminCommunities, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setCommunityTols(user?.community);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        dispatch(setAdminCommunities(adminCommunities));
        dispatch(setJoinedCommunities(joinedCommunities));
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));

        // get community id
        let communityId = '';
        //Get default community details
        // Get default administrators community details
        if (adminCommunities && adminCommunities.length > 0) {
          const defaultAdministratorsCommunity =
            _getDefaultAdministratorsCommunity(adminCommunities);
          if (
            defaultAdministratorsCommunity &&
            defaultAdministratorsCommunity?._id
          ) {
            communityId = defaultAdministratorsCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              console.log('res.data', res.data);
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
            }
            const communities = _getAdminCommunities(adminCommunities);
            // console.log(communities)
            store.dispatch(loadCommunities(communities));
          }
        }
        //   //Load All Communities
        //   const communitiesList = await getCommunities(tokens.accessToken);
        //  // console.log(communitiesList.data)
        //   if (response.status === 200) {
        //     store.dispatch(loadCommunities(communitiesList.data))
        //   }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
    // finally {s
    //   setLoading(false);
    // }
  };
  const logout = () => {
    removeTokens();
    setUser(null);
    setIsAuthenticated(false);
    setAccessToken(null);
    setRefreshToken(null);
    setCommunityTols(null);
    purgeStoredState();
    store.dispatch(logoutUser());
    store.dispatch(unloadSelectedCommunity());
    store.dispatch(unloadCommunities());
  };

  const autoLogin = async (
    phoneNumber: string,
    emailId: string,
    token: any
  ) => {
    try {
      console.log('token123', token);

      const formData = new FormData();

      formData.append('phoneNumber', phoneNumber);
      formData.append('emailId', emailId);
      let deviceToken = localStorage.getItem('deviceToken');

      if (!deviceToken) {
        deviceToken = await getDeviceToken();
        if (deviceToken) {
          localStorage.setItem('deviceToken', deviceToken);
        } else {
          console.warn('Device token is unavailable or permission is denied.');
        }
      }

      if (deviceToken) {
        formData.append('deviceToken', deviceToken);
      }

      const response = (await axios.post(
        `${BASE_URL}/auth/autoLogin`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, adminCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setCommunityTols(user?.community);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));

        // console.log("ADMIN COMMUNITIES ")
        // console.log( adminCommunities)

        //console.log("JOINED COMMUNITIES ")
        // console.log( joinedCommunities)
        // get community id
        let communityId = '';
        //Get default community details
        //console.log(tokens.accessToken)
        // Get default administrators community details
        if (adminCommunities && adminCommunities.length > 0) {
          const defaultAdministratorsCommunity =
            _getDefaultAdministratorsCommunity(adminCommunities);
          if (
            defaultAdministratorsCommunity &&
            defaultAdministratorsCommunity?._id
          ) {
            communityId = defaultAdministratorsCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
            }
            const communities = _getAdminCommunities(adminCommunities);
            // console.log(communities)
            store.dispatch(loadCommunities(communities));
          }
        }
        //   //Load All Communities
        //   const communitiesList = await getCommunities(tokens.accessToken);
        //  // console.log(communitiesList.data)
        //   if (response.status === 200) {
        //     store.dispatch(loadCommunities(communitiesList.data))
        //   }
      }

      return response;
    } catch (err: any) {
      setIsAuthenticated(false);
      return err?.response ? err?.response : err;
    }
  };
  const autoCreate = async (formData: any) => {
    try {
      const response = (await axios.post(
        `${BASE_URL}/auth/autoCreate`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, adminCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setCommunityTols(user?.community);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));

        // console.log("ADMIN COMMUNITIES ")
        // console.log( adminCommunities)

        //console.log("JOINED COMMUNITIES ")
        // console.log( joinedCommunities)
        // get community id
        let communityId = '';
        //Get default community details
        //console.log(tokens.accessToken)
        // Get default administrators community details
        if (adminCommunities && adminCommunities.length > 0) {
          const defaultAdministratorsCommunity =
            _getDefaultAdministratorsCommunity(adminCommunities);
          if (
            defaultAdministratorsCommunity &&
            defaultAdministratorsCommunity?._id
          ) {
            communityId = defaultAdministratorsCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
            }
            const communities = _getAdminCommunities(adminCommunities);
            // console.log(communities)
            store.dispatch(loadCommunities(communities));
          }
        }
        //   //Load All Communities
        //   const communitiesList = await getCommunities(tokens.accessToken);
        //  // console.log(communitiesList.data)
        //   if (response.status === 200) {
        //     store.dispatch(loadCommunities(communitiesList.data))
        //   }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
  };

  const _getDefaultAdministratorsCommunity = (data: IMemberCommunity[]) => {
    let result: ICommunity | undefined;
    data?.forEach((community) => {
      if (community.isDefault === true) {
        result = community.community;
        return false;
      }
    });
    return result ?? data[0].community;
  };

  const _getAdminCommunities = (data: IMemberCommunity[]) => {
    return data?.map((item) => ({
      ...item.community,
      id: item.community?._id ?? '', // Provide a default value if _id is undefined
    }));
  };

  return {
    user,
    isAuthenticated,
    loading,
    login,
    logout,
    getAccessToken,
    getCommunity,
    autoLogin,
    autoCreate,
  };
};
