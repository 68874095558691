import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';


export default function MobileNav() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);




  // console.log(selectedCommunity?.selectedCommunity?.collectSubscription, "selectedCommunity")







  const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });



  return (
    <Box sx={{ pb: 7, }} ref={ref}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 0.3 }} elevation={3} >
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', borderRadius: '10px 10px 0px 0px' }}>
          <Toolbar>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: 'auto' }}
            >
              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>Home</span>}
                icon={
                  <HomeRoundedIcon
                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/home' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/home' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}

                component={RouterLink}
                to="/home"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/home' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/home' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />

              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>Payments</span>}
                icon={
                  <PaymentsRoundedIcon

                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/payments' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/payments' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}
                component={RouterLink}
                to="/payments"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/payments' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/payments' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />
            </BottomNavigation>
            <StyledFab color="secondary" aria-label="add">
              <BottomNavigationAction icon={<AddIcon />}
                sx={{
                  background: "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                  padding: 0,
                  borderRadius: "50%",
                  minWidth: '15%',
                  height: '100%',
                  justifyContent: "center",
                  alignItems: "center",
                  color: 'white',
                  '&.Mui-selected': {
                    color: "#ffffff",
                  },
                }}
                component={RouterLink}
                to="/communities/add"
              />
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ minWidth: 'auto' }}
            >
              <BottomNavigationAction
                label={<span style={{ fontSize: '12px' }}>Plans</span>}
                icon={
                  <AccountBalanceWalletRoundedIcon
                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== '/courses' ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === '/courses' ? '#3C5AA0' : "#92929D",
                      }
                    }
                    }
                  />}

                component={RouterLink}
                to="/plans"

                sx={{
                  fontSize: '13px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: location.pathname !== '/courses' ? '#92929D' : "#3C5AA0",
                  textDecoration: 'none',
                  "& .Mui-selected": {
                    color: location.pathname === '/courses' ? '#3C5AA0' : "#92929D",
                  }
                }
                }
              />

              <BottomNavigationAction
                label={<span style={{ fontSize: '12px', fontFamily: 'Inter' }}>Subscribers</span>}
                icon={
                  <Groups2RoundedIcon
                    sx={{
                      fontSize: '20px',
                      color: location.pathname !== `/subscribers` ? '#92929D' : "#3C5AA0",
                      "& .Mui-selected": {
                        color: location.pathname === `/subscribers` ? '#3C5AA0' : "#92929D",
                      }
                    }}
                  />
                }
                sx={{
                  color: location.pathname !== `/subscribers` ? '#92929D' : "#3C5AA0",
                  "& .Mui-selected": {
                    color: location.pathname === `/subscribers` ? '#3C5AA0' : "#92929D",
                  }
                }}
                component={RouterLink}
                to={`/subscribers`}
              />

            </BottomNavigation>
          </Toolbar>
        </AppBar>
      </Paper>
    </Box>
  );
}

