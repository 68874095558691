import axios from "axios";
import { BASE_URL_V2 } from "../configurations/url.config";




export const getReports = async (token: string, communityId: string, planId: string, startDate: string, endDate: string) => {
  try {
    const response = await axios.post(`${BASE_URL_V2}/subscriptions/${communityId}`,
      {
        startDate: startDate,
        endDate: endDate,
        planId: planId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};