import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GetColor } from "./GetColor";
import Avatar from "@mui/material/Avatar";
import deepOrange from "@mui/material/colors/deepOrange";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useEffect, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IPaymentList } from "../../models/payment.model";
import { getStaticValue } from "../../utils/StaticValues";
import React from "react";
import { IColumn } from "../../models/table.model";
import { CustomTableHeader } from "./components/CustomTableHeader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface ITableProps {
  data: any;
  alternatePage?: React.ReactNode;
}
const columns: IColumn[] = [
  { title: "Payee", dataKey: "Payeedetails" },
  { title: "Community", dataKey: "community" },
  { title: "Plan Name", dataKey: "planname" },
  { title: "Cycle", dataKey: "paymentcycle" },
  { title: "Date", dataKey: "date" },
  { title: "Amount(₹)", dataKey: "amount" },
  { title: "Mode", dataKey: "mode" },
  { title: "Comments", dataKey: "comments" },
  { title: "Status", dataKey: "status" },
];
export const Payments = ({ data, alternatePage }: ITableProps) => {
  const [page, setPage] = useState(10);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);
  // const { initiatePaymentByIds, isLoading } = usePayment();
  // const [apiLoading, setApiLoading] = useState(false);
  // const handleRetryClick = async (communityId: string, planId: string) => {
  //   try {
  //     const response = await initiatePaymentByIds(communityId, planId);

  //     if (response?.url) {
  //       setUrl(response?.url);
  //     } else {
  //       console.error("Invalid response or missing URL");
  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //   } finally {
  //   }
  // };
  // React.useEffect(() => {
  //   if (url) {
  //     window.location.href = url;

  //     setUrl("");
  //   }
  // }, [url]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  // console.log(data);

  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined; // Allow undefined for initial state
  }>({});
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: "asc" | "desc" | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === "asc") {
        newSortDirections[columnTitle] = "desc";
      } else {
        newSortDirections[columnTitle] = "asc";
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };

  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    data?.sort((a: any, b: any) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === "Payeedetails") {
            const aValue = a?.udf1?.firstName;
            const bValue = b?.udf1?.firstName;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "community") {
            const aValue = a?.udf3?.name;
            const bValue = b?.udf3?.name;
            if (sortDirection === "asc") {
              return aValue < bValue;
            } else {
              return bValue > aValue;
            }
          } else if (column?.dataKey === "planname") {
            const aValue = a?.udf2?.name;
            const bValue = b?.udf2?.name;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column?.dataKey === "paymentcycle") {
            const aValue = a?.udf2?.offerEndsDuration;
            const bValue = b?.udf2?.offerEndsDuration;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === "mode") {
            const aValue = a?.mode;
            const bValue = b?.mode;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === "amount") {
            const aValue = a[column?.dataKey];
            const bValue = b[column?.dataKey];

            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === "comments") {
            const aValue = a?.udf4;
            const bValue = b?.udf4;
            if (sortDirection === "asc") {
              return aValue?.localeCompare(bValue);
            } else {
              return bValue?.localeCompare(aValue);
            }
          } else if (column.dataKey === "date") {
            const aValueDate = new Date(a?.addedon);
            const bValueDate = new Date(b?.addedon);
            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === "asc") {
                return aValueDate?.getTime() - bValueDate?.getTime();
              } else {
                return bValueDate?.getTime() - aValueDate?.getTime();
              }
            } else {
              const aValueTime = new Date(`${a?.addedon}T${a?.addedon}`);
              const bValueTime = new Date(
                `${b?.addedon?.date}T${b?.addedon?.time}`
              );
              if (sortDirection === "asc") {
                return aValueTime?.getTime() - bValueTime?.getTime();
              } else {
                return bValueTime?.getTime() - aValueTime?.getTime();
              }
            }
          } else {
            // const aValue = a[column?.dataKey];
            // const bValue = b[column?.dataKey];

            if (sortDirection === "asc") {
              //  return aValue.localeCompare(bValue);
            } else {
              //  return bValue.localeCompare(aValue);
            }
          }
        }
      }
      return 0;
    });
  }

  const paginatedData = data?.slice(startIndex, endIndex);

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: { xs: "calc(100vh - 50vh)", md: "calc(100vh - 35vh)" },
              pt: 1,
              pr: 2,
              pl: 2,
            }}
          >
            <Table
              // sx={{
              //   "& th": {
              //     fontSize: "12px",
              //     fontWeight: 700,
              //     color: "#565656",
              //     p: "0px 0px ",
              //   },
              //   "& td": {
              //     fontSize: "15px",
              //     fontWeight: 500,

              //     color: "#1A1A1A",
              //     p: "2px 0px 0px 0px ",
              //     "& .MuiTypography-root": { m: 0, fontSize: "11px" },
              //     "& .MuiTypography-root:nth-child(2)": {
              //       m: 0,
              //       fontSize: "11px",
              //       color: "#56565680",
              //       fontWeight: 600,
              //     },
              //   },
              // }}
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
                "@media (max-width: 1500px)": {
                  width: "100%", // Make the table 100% width
                  // Add horizontal scrolling
                },
                "& th": {
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#565656",
                  fontFamily: "Inter",
                  p: { xs: 0, xl: 0 },
                },
                "& td": {
                  fontSize: "15px",
                  fontWeight: 500,
                  fontFamily: "Inter",

                  color: "#1A1A1A",
                  p: "5px 1px 0px 0px",
                  "& .MuiTypography-root": {
                    m: 0,
                    fontSize: "11px",
                    width: "100px",
                  },
                },
              }}
            >
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#ffffff",
                }}
              >
                <TableRow sx={{ ml: 0 }}>
                  {columns.map((column: IColumn, i: number) => (
                    <TableCell
                      key={i}
                      onClick={() => handleSort(column?.title)}
                      sx={{}}
                    >
                      <CustomTableHeader
                        title={column?.title}
                        sortDirection={sortDirections[column?.title]}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ ml: 1 }}>
                {paginatedData?.map((order: IPaymentList, i: number) => (
                  <TableRow hover key={i}>
                    <TableCell sx={{ width: "200px" }}>
                      {columns[0].dataKey === "Payeedetails" && (
                        <>
                          {/* <TableCell sx={{width:"150px"}}> */}
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: deepOrange[500],
                                height: "26px",
                                width: "26px",
                                fontSize: "11px",
                              }}
                              alt="profile"
                              src={order?.udf1?.avatar}
                            />
                            <Box>
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "8px" }}
                              >
                                {order?.udf1?.firstName}
                              </Typography>
                              <Typography>
                                {order?.udf1?.phoneNumber}
                              </Typography>
                            </Box>
                          </Stack>
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[1].dataKey === "community" && (
                        <Typography sx={{ fontSize: "12px", color: "#565656" }}>
                          <span
                            style={{
                              color: "#4578F9",
                              textDecorationColor: "#565656",
                            }}
                          >
                            {/* {order?.udf3?.name} */}
                            <Tooltip
                              title={order?.udf3?.name}
                              placement="left-start"
                              arrow
                            >
                              <Avatar
                                sx={{
                                  height: "36px",
                                  width: "36px",
                                }}
                                src={order?.udf3?.logo}
                              ></Avatar>
                            </Tooltip>
                          </span>
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[2].dataKey === "planname" && (
                        <Typography>{order?.udf2?.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[3].dataKey === "paymentcycle" && (
                        <Typography
                          variant="caption"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            textAlign: 'center'
                          }}
                        >
                          {getStaticValue(order?.udf2?.duration)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[4].dataKey === "date" && (
                        <>
                          <Typography sx={{ color: "#1A1A1A" }}>
                            {/* {`${new Date(order?.addedon).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )} `} */}
                            {(() => {
                              const originalDate = new Date(order?.createdAt);
                              const istDate = new Date(
                                originalDate.getTime() + 5.5 * 60 * 60 * 1000
                              );
                              return istDate.toLocaleString("en-GB", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false, // 24-hour format
                              });
                            })()}
                          </Typography>
                          {/* <Typography>
                            {" "}
                            {`${new Date(order?.addedon).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}`}
                          </Typography> */}
                        </>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[5].dataKey === "amount" && (
                        <Typography >&#8377;{order?.amount}</Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      {columns[6].dataKey === "mode" && (
                        <>
                          <Typography>{order?.mode}</Typography>
                        </>
                      )}
                    </TableCell>
                    {
                      columns[7].dataKey === "comments" && (
                        <TableCell sx={{ width: "150px" }}>
                          <Tooltip
                            title={order?.udf4}
                            placement="left-start"
                            arrow
                            sx={{ backgroundColor: "red" }}
                          >
                            <Typography sx={{ fontSize: "11px", font: "Roboto" }}>
                              {order?.udf4?.length > 30
                                ? order?.udf4?.substring(0, 30) + "..."
                                : order?.udf4}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )
                    }

                    < TableCell sx={{ width: "150px" }}>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Grid
                          container
                          direction={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Grid item xs={12} md={6} lg={6} xl={4}>
                            <Typography
                              variant="caption"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                textTransform: "capitalize",
                                //  color: GetColor(order?.status),
                              }}
                            >
                              <FiberManualRecordIcon
                                key={i}
                                sx={{
                                  hieght: "10px",
                                  width: "10px",
                                  color: (getStaticValue(order?.status) || order?.status) === "Paid By Cash"
                                    ? "success.light"
                                    : GetColor(getStaticValue(order?.status) || order?.status),
                                }}

                              />
                              {getStaticValue(order?.status) || order?.status}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6} xl={6}></Grid>
                        </Grid>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableBody
                sx={{
                  "& td": {
                    borderColor: "#E0E0E0",
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#565656",

                    "& .MuiTypography-root": { fontSize: "15px" },
                  },
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={columns?.length + 1}
                    style={{ textAlign: "center", border: "none" }}
                  >
                    {alternatePage}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack
              direction={"row-reverse"}
              alignItems={"center"}
              sx={{
                color: "#A5A5A5",
                fontSize: "10px",
                width: { xs: "max-content", md: "100%" },
                direction: { xs: "row" },
              }}
            >
              <Typography sx={{ color: "#A5A5A5", fontSize: "13px" }}>
                {data?.length > 0
                  ? `Showing ${Math.min(
                    rowsPerPage,
                    data.length - page * rowsPerPage
                  )} of ${data.length} entries`
                  : "Showing 0 of 0 entries"}
              </Typography>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="View"
                labelDisplayedRows={() => null}
                SelectProps={{
                  SelectDisplayProps: {
                    style: { border: "1px solid #A5A5A5", borderRadius: "5px" },
                  },
                  // You can also customize the style of the menu options if desired
                  MenuProps: {
                    PaperProps: { style: { border: "1px solid #A5A5A5" } },
                  },
                }}
                ActionsComponent={({ onPageChange, page }) => {
                  const totalPages = Math.ceil(data?.length / rowsPerPage);
                  return (
                    <Stack
                      direction={"row"}
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      {/* <IconButton
                        onClick={(event) => onPageChange(event, 0)} // Go to the first page
                        disabled={page === 0}
                        aria-label="go to first page"
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </IconButton> */}

                      <IconButton
                        onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                        disabled={page === 0}
                        aria-label="go to previous page"
                      >
                        <KeyboardArrowLeft />
                      </IconButton>

                      {[...Array(3)].map((_, index) => {
                        const pageNumber = page + index;
                        if (pageNumber < totalPages) {
                          return (
                            <IconButton
                              key={pageNumber}
                              onClick={(event) =>
                                onPageChange(event, pageNumber)
                              }
                              style={{
                                fontSize: "1.9vh",
                                border:
                                  page === pageNumber
                                    ? "1px solid #3C5AA0"
                                    : "none", // Highlight the active page with a border
                                color:
                                  page === pageNumber ? "#3C5AA0" : "#000000", // Highlight the active page with a color
                                width: page === pageNumber ? "30px" : "30px",
                                height: page === pageNumber ? "30px" : "30px",
                              }}
                            >
                              {pageNumber + 1}
                            </IconButton>
                          );
                        } else {
                          return null; // Don't render the button if it's out of bounds
                        }
                      })}

                      <IconButton
                        onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                        disabled={page >= totalPages - 1}
                        aria-label="next page"
                      >
                        <KeyboardArrowRight />
                      </IconButton>

                      {/* <IconButton
                        onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                        aria-label="go to last page"
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </IconButton> */}
                    </Stack>
                  );
                }}
              />
            </Stack>
          </TableContainer>
        </Box >
      </PerfectScrollbar >
      <Stack
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          display: { xs: "block", md: "none" },
          mb: 5,
        }}
      >
        {paginatedData?.map((order: IPaymentList, i: number) => (
          <Accordion sx={{ boxShadow: "none" }} key={i}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={6}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src={order?.udf1?.avatar}
                      sx={{
                        height: "30px",
                        width: "30px",
                        fontSize: "11px",
                      }}
                      alt={`${order?.udf1?.firstName}`}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        display: "flex",
                      }}
                    >
                      {order?.udf1?.firstName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#565656",
                      fontSize: "14px",
                      textAlign: "end",
                      fontWeight: 500,
                    }}
                  >
                    &#8377;
                    {order?.amount}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      justifyContent: "end",
                    }}
                  >
                    <FiberManualRecordIcon
                      key={i}
                      sx={{
                        hieght: "10px",
                        width: "10px",
                        color: (getStaticValue(order?.status) || order?.status) === "Paid By Cash"
                          ? "success.light"
                          : GetColor(getStaticValue(order?.status) || order?.status),
                      }}
                    />
                    {getStaticValue(order?.status) || order?.status}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <Stack display={"flex"} flexDirection={"column"}>
                    <Typography sx={{ color: "#565656", fontSize: "10px" }}>
                      {order?.udf2?.name}
                    </Typography>
                    <Typography sx={{ color: "#565656", fontSize: "10px" }}>
                      {order?.udf2?.interval}{getStaticValue(order?.udf2?.duration)}
                    </Typography>
                    {order?.addedon ? (
                      <Typography
                        sx={{
                          color: "#565656",
                          fontSize: "10px",
                        }}
                      >
                        Paid on :{" "}
                        {(() => {
                          const originalDate = new Date(order?.addedon);
                          const istDate = new Date(
                            originalDate.getTime() + 5.5 * 60 * 60 * 1000
                          );
                          return istDate.toLocaleString("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          });
                        })()}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            color: "#565656",
                            fontSize: "10px",
                          }}
                        >
                          Paid on :{" "}
                          {(() => {
                            const originalDate = new Date(order?.createdAt);
                            const istDate = new Date(
                              originalDate.getTime() + 5.5 * 60 * 60 * 1000
                            );
                            return istDate.toLocaleString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                            });
                          })()}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack display={"flex"} flexDirection={"column"}>
                    <Typography
                      sx={{
                        color: "#565656",
                        fontSize: "10px",
                        textAlign: "end",
                      }}
                    >
                      {order?.mode}
                    </Typography>

                    {order?.dueDate ? (
                      <Typography
                        sx={{
                          color: "#565656",
                          fontSize: "10px",
                          textAlign: "end",
                        }}
                      >
                        Due on:{" "}
                        {(() => {
                          const originalDate = new Date(order?.dueDate);
                          const istDate = new Date(
                            originalDate.getTime() + 5.5 * 60 * 60 * 1000
                          );
                          return istDate.toLocaleString("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            hour12: false,
                          });
                        })()}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontFamily: "Roboto",
                          textAlign: "end",
                        }}
                      >
                        No Dues
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Divider></Divider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="View"
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: {
                border: "1px solid #A5A5A5",
                borderRadius: "5px",
                backgroundColor: "white",
              },
            },

            MenuProps: {
              PaperProps: { style: { border: "1px solid #A5A5A5" } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data?.length / rowsPerPage);
            return (
              <Stack
                direction={"row"}
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ p: 0 }}
              >
                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)}
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)}
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(2)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: "15px",
                          border:
                            page === pageNumber ? "1px solid #3C5AA0" : "none",
                          color: page === pageNumber ? "#3C5AA0" : "#000000",
                          width: page === pageNumber ? "30px" : "30px",
                          height: page === pageNumber ? "30px" : "30px",
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>

                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)}
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
              </Stack>
            );
          }}
        />
      </Stack>
    </>
  );
};
