import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PerfectScrollbar from "react-perfect-scrollbar";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import FavoriteIcon from "@mui/icons-material/Favorite";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import Button from "@mui/material/Button";

import { useEffect, useState } from "react";

import {
  AvatarGroup,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IPost } from "../Posts/Posts";
import React from "react";
import { CustomActions } from "./components/CustomActions";
import { IAction, IColumn } from "../../models/table.model";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { CustomTableHeader } from "./components/CustomTableHeader";
import CloseIcon from "@mui/icons-material/Close";
import { getStaticValue } from "../../utils/StaticValues";
import store from "../../store";
import { useSelector } from "react-redux";
import { ICommunity } from "../../models/communities.modal";
import { Carousel } from "react-responsive-carousel";
import { USER_APP_BASE_URL } from "../../configurations/url.config";
import ShareCommunityPost from "../Posts/ShareCommunityPost";

import { UserInfoCell } from "./components/UserInfoCell";

// const { Group436 } = icons;

interface IPostList extends IPost {
  actions: IAction[];
}
interface ITableProps {
  data: IPostList[];
  columns: IColumn[];
  onActionClick: (
    a: any,
    data: any,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => void;
  alternatePage?: React.ReactNode;
  onCheckboxClick: (postId: string) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRows: string[];
  onDeleteClick: () => void;
  onClearClick: () => void;
}
export interface ISortingState {
  [key: string]: "asc" | "desc";
}

export const PostsTable = ({
  data,
  onActionClick,
  columns,
  alternatePage,
  setSelectedRows,
  onDeleteClick,
  onCheckboxClick,
  selectedRows,
  onClearClick,
}: ITableProps) => {
  // checkbox logic  start for multi select
  const [openModals, setOpenModals] = useState<boolean[]>([]);
  // eslint-disable-next-line
  const [openReport, setOpenReport] = useState<boolean>(false);
  // eslint-disable-next-line
  const [_selectedRow, setSelectedRow] = useState<IPost | null>(null);
  const [link] = useState("");
  // used for  open the model of Post Preview

  const handleOpen = (index: number) => {
    setOpenModals((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = true;
      return updatedOpenModals;
    });
  };
  const handleClose = (index: number) => {
    setOpenModals((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = false;
      return updatedOpenModals;
    });
  };

  console.log(data, "data");
  const handleOpenReport = (item: IPost) => {
    setSelectedRow(item);
    setOpenReport(true);
  };

  // const handleCloseReport = () => {
  //   setOpenReport(false);
  // };

  const sortedData = [...data];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    console.log(newPage);
  };
  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: "asc" | "desc" | undefined } = {};
      let newSortedColumns: string[] = [];

      if (prevSortDirections[columnTitle] === "asc") {
        newSortDirections[columnTitle] = "desc";
      } else {
        newSortDirections[columnTitle] = "asc";
      }

      newSortedColumns.push(columnTitle);
      setSortedColumns(newSortedColumns);
      return newSortDirections;
    });
  };
  const sortedColumnTitles = sortedColumns.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );
  if (sortedColumnTitles.length > 0) {
    sortedData.sort((a: IPostList, b: IPostList) => {
      for (const columnTitle of sortedColumnTitles) {
        const sortDirection = sortDirections[columnTitle];
        const column = columns.find((col) => col.title === columnTitle);
        if (column) {
          if (column.dataKey === "post") {
            const aValue = a?.content;
            const bValue = b?.content;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "communityType") {
            // const aValue = a.communities?.length;
            // const bValue = b.communities?.length;
            // if (sortDirection === "asc") {
            //   return aValue<bValue
            // } else {
            //   return bValue>aValue;
            // }
          } else if (column.dataKey === "postedBy") {
            // const aValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
            // const bValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
            const aValue = a?.createdBy?.firstName;
            const bValue = b?.createdBy?.firstName;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "visibility") {
            // const aValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
            // const bValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
            const aValue = a?.whoCan;
            const bValue = b?.whoCan;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "roleType") {
            // const aValue = `${a?.createdBy?.role} ${a?.createdBy?.lastName}`;
            // const bValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
            const aValue = a?.createdBy?.role;
            const bValue = b?.createdBy?.role;
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          } else if (column.dataKey === "postedOn") {
            const aValueDate = new Date(a?.updatedAt);
            const bValueDate = new Date(b.updatedAt);
            if (aValueDate.getTime() !== bValueDate.getTime()) {
              // If the dates are not the same, sort based on date
              if (sortDirection === "asc") {
                return aValueDate.getTime() - bValueDate.getTime();
              } else {
                return bValueDate.getTime() - aValueDate.getTime();
              }
            } else {
              // If dates are the same, include time for sorting
              // const aValueTime = new Date(
              //   `${a.createdAt}T${a.createdAt}`
              // );
              // const bValueTime = new Date(
              //   `${b.updated.date}T${b.updated.time}`
              // );
              // if (sortDirection === "asc") {
              //   return aValueTime.getTime() - bValueTime.getTime();
              // } else {
              //   return bValueTime.getTime() - aValueTime.getTime();
              // }
            }
          } else {
            // const aValue = a[column?.dataKey];
            // const bValue = b[column?.dataKey];

            if (sortDirection === "asc") {
              //  return aValue.localeCompare(bValue);
            } else {
              //  return bValue.localeCompare(aValue);
            }
          }
        }
      }
      return 0;
    });
  }
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const state = store.getState();
  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });
  const [clickedCommunity, setClickedCommunity] = useState<ICommunity | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleJoinCommunity = (community: ICommunity | null) => {
    console.log(community);
    setClickedCommunity(community);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setClickedCommunity(null);
    setModalOpen(false);
    setCopied(false);
  };

  const handleCopyLink = (communityId: string) => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${communityId}/about`;
    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.selectedCommunity?._id}/about`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.selectedCommunity?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.selectedCommunity?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.selectedCommunity?._id}/about`
  )}`;
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexWrap: "wrap",
  };

  const imageContainerStyle: React.CSSProperties = {
    position: "relative",
    display: "inline-block",
    margin: "5px", // Add margin as needed
  };

  const imageStyle: React.CSSProperties = {
    width: "50px",
    height: "50px",
    cursor: "pointer",
    // Add any other styles for your images
  };

  const plusIconStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "red",
    // Set the background color for the icon
    // #3C5AA0
    color: "#fff", // Set the text color for the icon
    width: "13px",
    height: "13px",
    lineHeight: "13px",
    textAlign: "center",
    fontSize: "9px",
    borderRadius: "50%",
    cursor: "pointer",

    // Add any other styles for your plus icon
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleOpenPopover = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };
  const id = open ? "simple-popover" : undefined;

  //  console.log(paginatedData);
  return (
    <>
      {" "}
      {selectedRows.length > 0 && (
        <Stack spacing={3} direction={"row"}>
          <ClearOutlinedIcon
            sx={{ cursor: "pointer", color: "#1C1B1F" }}
            onClick={onClearClick}
          />
          <Typography sx={{ color: "#1C1B1F", fontWeight: "500" }}>
            {`${selectedRows?.length} Selected `}
          </Typography>

          <DeleteOutlineOutlinedIcon
            sx={{ cursor: "pointer" }}
            onClick={handleOpenPopover}
          />

          {/* <ShareRounded sx={{ cursor: "pointer" }} /> */}
        </Stack>
      )}
      {paginatedData.map((order: IPost, i: number) => (
        <Dialog
          open={openModals[i] || false}
          onClose={() => handleClose(i)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          key={i}
          fullWidth
          maxWidth="md"
          fullScreen={isSmallScreen}
          PaperProps={{ sx: { width: "100%" } }}
        >
          <Box>
            <Grid
              container
              sx={{ flexDirection: isSmallScreen ? "column-reverse" : "row" }}
            >
              {order.images.length > 0 && (
                <Grid item xl={6} md={6} xs={12} lg={6}>
                  <Carousel showThumbs={false} infiniteLoop>
                    {order.images.map((imageUrl, j) => (
                      <div key={j} style={{ textAlign: "center" }}>
                        <img
                          src={imageUrl}
                          alt="item"
                          height="398px"
                          width="300px"
                        />
                      </div>
                    ))}
                  </Carousel>
                </Grid>
              )}

              <Grid
                item
                xl={order.images.length > 0 ? 6 : 12}
                md={order.images.length > 0 ? 6 : 12}
                xs={order.images.length > 0 ? 6 : 12}
                sm={order.images.length > 0 ? 6 : 12}
                sx={{ maxWidth: "100%" }}
              >
                <Stack
                  direction={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ backgroundColor: "#F0F9FF" }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={order?.createdBy?.avatar}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            color: "#1E1E1E",
                            fontSize: "15px",
                            fontWeight: "400",
                            fontFamily: "Inter",
                          }}
                        >
                          {order?.createdBy?.firstName +
                            " " +
                            order?.createdBy?.lastName}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          sx={{
                            color: "#787878",
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "Inter",
                          }}
                        >
                          {order?.hashTag}
                        </Typography>
                      }
                    />
                  </ListItem>

                  <CloseIcon
                    sx={{
                      backgroundColor: "#3C5AA0",
                      padding: "4px",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      mt: "0.5px",
                      mr: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        // backgroundColor: "#50A1CA", // Background color on hover
                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                    onClick={() => handleClose(i)}
                  />
                </Stack>

                <Stack
                  sx={{
                    ml: 2,
                    mt: 2,
                    mr: 4,
                    mb: 1,
                    height: "200px",
                    width: "95%",
                    color: "#464646",
                    fontSize: "13px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    textAlign: "justify",
                    "&::-webkit-scrollbar": {
                      width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  }}
                >
                  <TextField
                    minRows={5}
                    multiline
                    fullWidth
                    variant="standard"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    value={order?.content} // or placeholder={order.content} if it's a placeholder
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true, // Prevents user input
                      style: { fontSize: "13px", fontFamily: "Inter" }, // Style for the text content
                    }}
                  />
                </Stack>

                <Stack
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                >
                  <Typography
                    sx={{ mr: 2, fontFamily: "Inter", fontSize: "13px" }}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <FavoriteIcon style={{ color: "red" }} />
                    {"  "}
                    {order.likedBy?.length}
                    {"  "} Likes
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    bottom: 0,
                  }}
                >
                  <Divider style={{ width: "100%" }} />

                  <Stack
                    direction={"row"}
                    sx={{ m: 1 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={6}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#565656",
                        fontFamily: "Inter",
                      }}
                    >
                      Posted on: {order?.createdAt}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#565656",
                        fontFamily: "Inter",
                      }}
                    >
                      Visibility :{getStaticValue(order.whoCan)}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      ml: 1,
                      color: "#565656",
                      fontFamily: "Inter",
                    }}
                  >
                    Post status: {order?.status}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      ))}
      {/* <Dialog
        fullWidth
        maxWidth="sm"
         open={openReport}
        onClose={() => handleCloseReport()}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "#F0F9FF", height: "50px", px: 2 }}
          >
            <Typography
              sx={{
                color: "#3C5AA0",
                fontSize: "20px",
                fontWeight: "500",
                backgroundColor: "#F0F9FF",
              }}
            >
              Reported Post Review
            </Typography>
            <Box
              sx={{
                backgroundColor: "#3C5AA0",
                padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleCloseReport()}
            >
              <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
            </Box>
          </Grid>
          <DialogContent sx={{ padding: "5px 20px" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
            >
              3 Reports
            </Typography>

            <Grid
              sx={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "flex-start",

                mt: 2,
              }}
            >
              <Grid>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Reason For Reporting :
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  sx={{
                    // mx: 1,
                    // px: 1,
                    ml: 1,
                    fontSize: "13px",
                  }}
                >
                  - Missuse(6),
                </Typography>
                <Typography
                  sx={{
                    // mx: 1,
                    // px: 1,
                    ml: 1,
                    fontSize: "13px",
                  }}
                >
                  - MisLeading(7)
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Comments :
              </Typography>
              <Typography
                sx={{
                  mx: 1,
                  borderRadius: "5px",
                  fontSize: "13px",
                }}
              >
                This User is Fake,
              </Typography>
              <Typography
                sx={{
                  borderRadius: "5px",
                  fontSize: "13px",
                }}
              >
                Pretending to me.
              </Typography>
            </Grid>
            <Grid sx={{ mt: 1 }}>
              <Typography sx={{ fontSize: "12px" }}>
                Take Action Now.
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Reason for your action"
                size="medium"
                fullWidth
                variant="outlined"
                // rows={2}
                inputProps={{
                  style: { width: "100%", height: "20%", fontSize: "13px" },
                }}
                style={{ borderRadius: "30px", maxWidth: "100%" }}
                // value={slug}
                // onChange={(e: any) => setSlug(e.target.value)}
              />
            </Grid>

            <Stack
              direction={"row"}
              spacing={2}
              sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                sx={{
                  px: 2,
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                Remove Post
              </Button>
              <Button
                sx={{
                  px: 2,
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                Send Warning
              </Button>

              <Button
                sx={{
                  px: 2,
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                Reject all
              </Button>
            </Stack>
          </DialogContent>
        </>
      </Dialog> */}
      <PerfectScrollbar>
        <Box>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ height: "calc(100vh - 30vh)" }}
          >
            <Table
              sx={{
                maxWidth: "100%",
                "@media (max-width: 1500px)": {
                  width: "100%", // Make the table 100% width
                  overflowX: "auto", // Add horizontal scrolling
                },
                "& th": {
                  fontSize: "13px",
                  fontWeight: 700,
                  color: "#565656",
                  pt: 0,
                  pl: 1,
                  pb: 0,
                },
                "& td": {
                  borderColor: "#E0E0E0",
                  fontSize: "13px",
                  fontWeight: 400,
                  color: "#565656",
                  p: "0px 10px 0px 10px",

                  "& .MuiTypography-root": { fontSize: "11px" },
                },
              }}
            >
              <TableHead
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "#ffffff",
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    sx={{ pl: "11px", pt: "0px" }}
                    style={{
                      transform: "scale(1)",
                    }}
                    checked={selectedRows.length === data.length}
                    onChange={() =>
                      selectedRows.length === data.length
                        ? setSelectedRows([])
                        : setSelectedRows(data.map((item) => item._id))
                    }
                  />
                </TableCell>
                {columns.map((column: IColumn, i: number) => (
                  <TableCell key={i} onClick={() => handleSort(column.title)}>
                    <CustomTableHeader
                      title={column.title}
                      sortDirection={sortDirections[column.title]}
                    />
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {paginatedData.map((order: IPost, i: number) => (
                  <TableRow
                    hover
                    key={order._id}
                    // selected={setSelectedRows.indexOf(i) !== -1}
                  >
                    <TableCell
                      sx={{ display: "flex", alignItems: "center" }}
                      padding="checkbox"
                    >
                      {/* Individual Row Checkbox */}
                      <Checkbox
                        size="small"
                        sx={{
                          transform: "scale(1)",
                          paddingBottom: {
                            xs: "43px",
                            sm: "20px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                        }}
                        checked={selectedRows.includes(order._id)}
                        onChange={(event) => {
                          event.stopPropagation();
                          onCheckboxClick(order?._id);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Card
                          elevation={0}
                          sx={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "2px",
                          }}
                        >
                          {/* {order.images.map((imageUrl, j) => (
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height="100%"
                              image={imageUrl}
                              onClick={() => handleOpen(i)}
                              sx={{ cursor: "pointer" }}
                            />
       
                          ))}
                          {order.images.length > 0 && (
                            <Typography
                              sx={{
                                position: "absolute",
                                ml: "34px",
                                mt: "-19px",
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px !important",
                                textShadow: "-1px -1px 5px #000000",

                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleOpenReport(order);

                              }}
                            >
                              {order.images.length > 1
                                ? `${order.images.length}+`
                                : ""}
                            </Typography>
                          )} */}

                          <div style={containerStyle}>
                            {order?.images.map((imageUrl, j) => (
                              <div key={j} style={imageContainerStyle}>
                                <img
                                  src={imageUrl}
                                  alt={""}
                                  style={imageStyle}
                                  onClick={() => handleOpen(i)}
                                />
                                {order.images.length > 1 && (
                                  <div
                                    style={plusIconStyle}
                                    onClick={() => handleOpenReport(order)}
                                  >
                                    {order?.images?.length > 1
                                      ? `+${order?.images?.length - 1}`
                                      : order?.images?.length}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Card>

                        {columns[0].dataKey === "post" && (
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontSize: "11px",
                              color: "#000000",
                              cursor: "pointer",
                              textDecoration: "none",
                              font: "Inter",
                            }}
                            onClick={() => handleOpen(i)}
                          >
                            {order?.content?.length > 15
                              ? order?.content?.substring(0, 15) + "..."
                              : order?.content}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    {columns[1].dataKey === "communityType" && (
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <AvatarGroup total={order.communities.length} max={6}>
                            {order?.communities.map((community, index) => (
                              <Tooltip
                                title={community.name}
                                key={index}
                                sx={{ cursor: "pointer" }}
                              >
                                <Avatar
                                  onClick={() => handleJoinCommunity(community)}
                                  key={index}
                                  src={community?.logo}
                                />
                              </Tooltip>
                            ))}
                          </AvatarGroup>
                        </Stack>
                      </TableCell>
                    )}
                    {columns[2].dataKey === "postedBy" && (
                      <TableCell sx={{ width: "15vw" }}>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          {/* <Avatar
                            sx={{
                              bgcolor: "white",
                              height: "30px",
                              width: "30px",
                              fontSize: "11px",
                            }}
                            alt={order?.createdBy?.firstName}
                            src={order?.createdBy?.avatar}
                          >
                           
                          </Avatar> */}

                          <UserInfoCell
                            name={
                              order?.createdBy?.firstName +
                              "" +
                              order?.createdBy?.lastName
                            }
                            avatar={order?.createdBy?.avatar}
                            avatarStyles={{
                              height: "24px",
                              width: "24px",
                              fontSize: "11px",
                            }}
                          ></UserInfoCell>
                        </Stack>
                      </TableCell>
                    )}
                    {columns[3].dataKey === "roleType" && (
                      <TableCell>
                        {/* <Select
                      value={"4"}
                      sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        "& .MuiSelect-select": {
                          padding: "0px 25px 0px 0px",
                        },
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                      }}
                    >
                      {postStatusList.map((status, i: number) => (
                        <MenuItem
                          key={i}
                          value={status.value}
                          sx={{ fontSize: "11px", fontWeight: "400" }}
                        >
                          {status.lable}
                        </MenuItem>
                      ))}
                    </Select> */}
                        <Stack>
                          <Typography>
                            Role: {getStaticValue(order?.mapping[0]?.type)}
                          </Typography>
                          <Typography>
                            {/* {getStaticValue(order.createdBy?.role)} */}
                            {order?.mapping[0]?.slug}
                          </Typography>
                        </Stack>
                      </TableCell>
                    )}
                    {/* {columns[4].dataKey === "visibility" && (
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#565656",
                            fontWeight: "400",
                            fontSize: "11px",
                          }}
                        >
                          {getStaticValue(order?.whoCan)}
                        </Typography>
                      </TableCell>
                    )} */}
                    {columns[4].dataKey === "postedOn" && (
                      <TableCell>
                        <Typography
                          sx={{
                            color: "#565656",
                            fontWeight: "400",
                            fontSize: "11px",
                          }}
                        >
                          {order?.updatedAt}
                          {/* {order.updatedAt.toLocaleString()} */}
                        </Typography>
                        <Typography
                          sx={{ m: 0, fontSize: "10px", color: "#56565680" }}
                        >
                          {order?.updatedAtTime}
                        </Typography>
                      </TableCell>
                    )}
                    {/* <TableCell>{getStaticValue(order.status)}</TableCell> */}

                    <TableCell>
                      <CustomActions
                        actions={order.actions}
                        onActionClick={(action: any, e: any) =>
                          onActionClick(action, order, e)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableBody
                sx={{
                  "& td": {
                    borderColor: "#E0E0E0",
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#565656",
                    p: "10px 0px",
                    "& .MuiTypography-root": { fontSize: "15px" },
                  },
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={columns.length + 1}
                    style={{ textAlign: "center", border: "none" }}
                  >
                    {alternatePage}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Stack
          direction={"row-reverse"}
          alignItems={"center"}
          sx={{
            color: "#A5A5A5",
            fontSize: { xs: "10px", md: "1.5vh" },
            pr: { xs: "0px", md: "25px" },
          }}
        >
          <Typography
            sx={{
              color: "#A5A5A5",

              fontSize: { xs: "10px", md: "13PX" },
              display: { xs: "none", md: "block" },
            }}
          >
            Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{" "}
            {data.length} Entries
          </Typography>
          <TablePagination
            // rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="View"
            labelDisplayedRows={() => null}
            SelectProps={{
              SelectDisplayProps: {
                style: { border: "1px solid #A5A5A5", borderRadius: "5px" },
              },
              // You can also customize the style of the menu options if desired
              MenuProps: {
                PaperProps: { style: { border: "1px solid #A5A5A5" } },
              },
            }}
            ActionsComponent={({ onPageChange, page }) => {
              const totalPages = Math.ceil(data.length / rowsPerPage);

              return (
                <Stack
                  direction={"row"}
                  display="flex"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  {/* <IconButton
                    onClick={(event) => onPageChange(event, 0)} // Go to the first page
                    disabled={page === 0}
                    aria-label="go to first page"
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </IconButton> */}

                  <IconButton
                    onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                    disabled={page === 0}
                    aria-label="go to previous page"
                  >
                    <KeyboardArrowLeft />
                  </IconButton>

                  {[...Array(3)].map((_, index) => {
                    const pageNumber = page + index;
                    if (pageNumber < totalPages) {
                      return (
                        <IconButton
                          key={pageNumber}
                          onClick={(event) => onPageChange(event, pageNumber)}
                          style={{
                            fontSize: "1.9vh",
                            border:
                              page === pageNumber
                                ? "1px solid #3C5AA0"
                                : "none", // Highlight the active page with a border
                            color: page === pageNumber ? "#3C5AA0" : "#000000", // Highlight the active page with a color
                            width: page === pageNumber ? "30px" : "30px",
                            height: page === pageNumber ? "30px" : "30px",
                          }}
                        >
                          {pageNumber + 1}
                        </IconButton>
                      );
                    } else {
                      return null; // Don't render the button if it's out of bounds
                    }
                  })}

                  <IconButton
                    onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                    disabled={page >= totalPages - 1}
                    aria-label="next page"
                  >
                    <KeyboardArrowRight />
                  </IconButton>

                  {/* <IconButton
                    onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                    aria-label="go to last page"
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </IconButton> */}
                </Stack>
              );
            }}
          />
        </Stack>
        {/* </SimpleBar> */}
      </PerfectScrollbar>
      {clickedCommunity && (
        <ShareCommunityPost
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          dpSrc={clickedCommunity.logo || ""}
          title={clickedCommunity.name || ""}
          city={clickedCommunity.city || ""}
          members={clickedCommunity.members || []}
          communityId={clickedCommunity._id}
          handleCopyLink={() => handleCopyLink(clickedCommunity._id)}
          copied={copied}
          link={link}
          status={clickedCommunity?.status || ""}
          whatsappShareUrl={whatsappShareUrl}
          facebookShareUrl={facebookShareUrl}
          linkedinShareUrl={linkedinShareUrl}
          twitterShareUrl={twitterShareUrl}
          coverImageSrc={clickedCommunity.banner}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 2,
            color: "#494949",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "11px",
            fontFamily: "inter",
            padding: "0px 16px 16px 16px",
            maxWidth: "275px",
            color: "#565656",
          }}
        >
          Do you really want to delete this record? This process cannot be
          undone.
          {/* Delete Post */}
        </Typography>
        <Divider />
        <Stack direction="row" sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClosePopover}
            sx={{
              textTransform: "capitalize",
              borderColor: "#3C5AA0",
              color: "#3C5AA0",
              "&:hover": {
                borderColor: "#3C5AA0",
              },
            }}
          >
            Cancel
          </Button>
          <Stack direction="row-reverse" flexGrow={1}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                onDeleteClick();
                handleClosePopover();
              }}
              sx={{
                background: "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",

                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#3C5AA0",
                  borderColor: "#3C5AA0",
                },
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
