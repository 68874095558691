import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Link from "@mui/material/Link";
import { CreateCommunityPage2 } from "./CreateCommunityPage2";
import { useCommunity } from "../../hooks/useCommunity";
import { useCategories } from "../../hooks/useCategories";
import { ICategory } from "../../models/category.model";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext, IAuthContext } from "../../contexts/Auth.context";
import { communityById } from "../../services/communities.service";

import { ICreateCommunity } from "../../models/communities.modal";
import { metaConfig } from "../../utils/constants";

type CreateCommunityProps = {
  communitydata: ICreateCommunity | null;
};

export const CreateCommunity: React.FC<CreateCommunityProps> = ({
  communitydata,
}) => {
  const categories = useCategories();
  const { isCommunityExist } = useCommunity();
  const [page, setPage] = useState(1);
  const [isCommunityAvailable, setIsCommunityAvailable] =
    useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const [communityNameAvailability, setCommunityNameAvailability] =
    React.useState("");
  const [communityNameColor, setCommunityNameColor] = React.useState("");
  const [communityNameModified, setCommunityNameModified] = useState(false);
  const [CommunityData, setCommunityData] = useState<ICreateCommunity>(() => {
    return (
      communitydata || {
        name: "",
        category: "",
        zipCode: "",
        fullAddress: "",
        type: "PRIVATE",
        collectSubscription: "YES",
        city: "",
        maxMembers: 0,
        gstNumber: "",
        status: "CREATED",
        otherCategory: "",
      }
    );
  });

  const [communityName, setCommunityName] = useState("");
  const [open, setOpen] = useState(false);

  const checkCommunity = async () => {
    if (communityName || (communitydata && !id)) {
      const data = await isCommunityExist(
        communityName || communitydata?.name || ""
      );
      if (data) {
        setCommunityNameAvailability(
          "✅" + (communityName || communitydata?.name || "") + " is available"
        );
        setCommunityNameColor("#00d26a");
        setIsCommunityAvailable(true);
      } else {
        setCommunityNameAvailability(
          "🚫" +
            (communityName || communitydata?.name || "") +
            " is not available"
        );
        setCommunityNameColor("red");
      }
    }
  };

  const isSaveButtonEnabled = () => {
    const val = isCommunityAvailable && (selectedCategory || CommunityData.otherCategory) !== '' ? true : false;
    return val;
  };

  const handleCategorySelection = (categoryId: string) => {
    if (categoryId === "Other") {
      setOpen(true);
      setSelectedCategory(categoryId);
      const temp = {
        ...CommunityData,
        category: categoryId,
        otherCategory: CommunityData?.otherCategory,
      };
      setCommunityData(temp);
    } else {
      setOpen(false);
      setSelectedCategory(categoryId);
      const temp = {
        ...CommunityData,
        category: categoryId,
        otherCategory: "",
      };
      setCommunityData(temp);
    }
  };

  const setCurrentCommunityName = (val: string) => {
    if (val) {
      setCommunityName(val);
      setIsCommunityAvailable(false);
      if (id) {
        setCommunityNameModified(true);
      }
    } else {
      setCommunityNameModified(true);
      setIsCommunityAvailable(false);
      setCommunityNameAvailability("");
    }
  };
  const location = useLocation();
  const isExploreCreateCommunityPage =
    location.pathname === "/explore-create-community";

  const { id } = useParams();
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  // eslint-disable-next-line 
  const [initialCommunityName, setInitialCommunityName] = useState("");
  useEffect(() => {
    if (!id) {
      return;
    }
    if (id && !communitydata) {
      const fetchCommunity = async () => {
        try {
          const { data } = await communityById(getAccessToken(), id);
          setCommunityData({
            ...CommunityData,
            name: data.name,
            category: data.category,
            zipCode: data.zipCode,
            fullAddress: data.fullAddress,
            gstNumber: data.gstNumber,
            type: data.type,
            collectSubscription: data.collectSubscription,
            maxMembers: data.maxMembers,
            city: data.city,
            status: data?.status,
            otherCategory: data?.otherCategory,
          });
          setCurrentCommunityName(data.name);
          setCommunityNameColor("#00d26a");
          setCommunityNameAvailability("✅" + data?.name + " is available");
          setIsCommunityAvailable(true);
          setCommunityNameModified(false);
          setSelectedCategory(data?.category);
          if (data?.category === "Other") {
            setOpen(true);
          }
        } catch (error) {
        } finally {
        }
      };

      fetchCommunity();
    }
    setCommunityNameModified(false); 
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, communitydata]);

  const updateCommunityData = (updatedData: Partial<ICreateCommunity>) => {
    setCommunityData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  useEffect(() => {
    if (!id) {
      if (communitydata?.category === "Other" && categories.list.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
        setSelectedCategory(communitydata?.category || "");
        setInitialCommunityName(communitydata?.name || "");
      }
      setCommunityNameModified(false);
      if (communitydata) {
        setIsCommunityAvailable(true);
        setCommunityNameAvailability(
          "✅" + communitydata.name + " is available"
        );
      }
    }
    setCommunityNameModified(true);

    ////when  id is thier
    if (id) {
      if (communitydata?.category === "Other" && categories.list.length > 0) {
        setOpen(true);
        setSelectedCategory(communitydata?.category || "");
      } else {
        setIsCommunityAvailable(true);
        setCommunityNameModified(false);
        setSelectedCategory(communitydata?.category || "");
        if (communitydata) {
          // setCommunityNameModified(false);
          setCommunityNameAvailability(
            "✅" + communitydata?.name + " is available"
          );
          setCommunityNameColor("#00d26a");
        }
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communitydata, categories.list]);
  return (
    <Stack direction={"column"} spacing={1} height={"100%"}>
      <Helmet>
        <title>{metaConfig.title.createCommunity}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      {page === 1 && (
        <>
          <Stack>
            <Typography
              sx={{
                fontSize: {xs:"15px", md :"20px"},
                fontWeight: 500,
                color: isExploreCreateCommunityPage ? "#454545" : "#3760A9",
                fontFamily: "Inter",
              }}
            >
              Create a community
            </Typography>
          </Stack>

          {/* Large Screen  */}
          <Paper elevation={0} sx={{ height: "80vh" }}>
            <Box sx={{ mt: 3 }}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xlg: "block",
                  },
                }}
              >
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  spacing={1}
                  sx={{ mt: 3, ml: 2 }}
                >
                  <Box
                    sx={{
                      padding: "10px",
                      border: "1px solid #E0E0E0",
                      borderColor: "business.main",
                      backgroundColor: "common.white",
                      display: "inline-block",
                      borderRadius: "10px",

                      "& > input": {
                        border: "none",
                        background: "transparent",
                        fontSize: "24px",
                        fontWeight: 400,
                      },
                      "& > input:focus-visible": {
                        outline: "none !important",
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        display: "inline-block",
                        fontWeight: 400,
                        ml: 2,
                        color: "#D3D3D3",
                        fontSize: "18px",
                        fontFamily: "Inter",
                      }}
                    >
                      onecommunn.com/
                    </Typography>
                    <input
                      type="text"
                      required
                      value={CommunityData?.name}
                      onChange={(e) => {
                        setCurrentCommunityName(e.target.value);
                        const temp = {
                          ...CommunityData,
                          ...communitydata,
                          name: e.target.value,
                        };
                        setCommunityData(temp);
                      }}
                      placeholder="Enter your community name"
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter",

                        // color: "#1A1A1A"
                      }}
                    />
                  </Box>

                  <Button
                    variant="contained"
                    color="economy"
                    onClick={checkCommunity}
                    disabled={!communityNameModified}
                    sx={{
                      ml: 2,
                      mb: 1.6,
                      borderRadius: "10px",
                      boxShadow:'none',
                      fontSize: "18px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                      textTransform: "capitalize",
                      background: isExploreCreateCommunityPage
                        ? "linear-gradient(to right bottom, #50a1ca, #3b9b7f)"
                        : "linear-gradient(to right bottom, #3C5AA0, #50A1CA)",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                        xlg: "block",
                      },
                      "&:hover": {
                        backgroundColor: "#50A1CA", // Background color on hover
                        cursor: "pointer",
                        border: "none",
                      },
                    }}
                  >
                    Check Availability
                  </Button>
                </Stack>
              </Box>

              {/* Large Screen  End*/}

              {/* Mobile Screen */}

              <Stack
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xlg: "none",
                  },
                }}
              >
                <Box>
                  <Stack
                    direction={"row"}
                    alignContent={"center"}
                    spacing={1}
                    sx={{ mt: 2, ml: 2, display: "grid", mr: 2 }}
                  >
                    <Box
                      sx={{
                        padding: "10px",
                        border: "1px solid #C6C6C6",
                        // borderColor: "business.main",
                        backgroundColor: "common.white",
                        display: "inline-block",
                        borderRadius: "10px",

                        "& > input": {
                          border: "none",
                          background: "transparent",
                          fontSize: "24px",
                          fontWeight: 400,
                        },
                        "& > input:focus-visible": {
                          outline: "none !important",
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          display: "inline-block",
                          fontWeight: 600,
                          ml: 2,
                          color: "#D3D3D3",
                          fontSize: "11px",
                        }}
                      >
                        onecommunn.com/
                      </Typography>
                      <input
                      type="text"
                      required
                      value={CommunityData?.name}
                      onChange={(e) => {
                        setCurrentCommunityName(e.target.value);

                        const temp = {
                          ...CommunityData,
                          ...communitydata,
                          name: e.target.value,
                        };

                        setCommunityData(temp);
                      }}
                      placeholder="Enter community name"
                      style={{
                        fontSize: "11px",
                        fontWeight: "400",

                        // color: "#1A1A1A"
                      }}
                    
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
              <Stack sx={{ mr: 4 }}>
                <Button
                  variant="contained"
                  color="economy"
                  onClick={checkCommunity}
                  disabled={!communityNameModified}
                  fullWidth
                  sx={{
                    ml: 2,
                    mb: 1.6,
                    mr: 2,
                    mt: 2,
                    borderRadius: "10px",
                    boxShadow:'none',
                    fontSize: "15px",
                    fontFamily:'Inter',
                    fontWeight: 500,
                    textTransform: "capitalize",
                    background: isExploreCreateCommunityPage
                      ? "linear-gradient(to right bottom, #50a1ca, #3b9b7f)"
                      : "linear-gradient(to right bottom, #3C5AA0, #50A1CA)",
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "none",
                      xlg: "none",
                    },
                    "&:hover": {
                      backgroundColor: "#50A1CA", // Background color on hover
                      cursor: "pointer",
                      border: "none",
                    },
                  }}
                >
                  Check Availability
                </Button>
              </Stack>

              {/* Mobile Screen End  */}

              <Typography
                variant="body1"
                sx={{
                  color: `${communityNameColor}`,
                  fontSize: "14px",
                  fontFamily: "Inter",
                  mt: 1,
                  ml: 2,
                }}
              >
                {`${communityNameAvailability}`}
              </Typography>
            </Box>

            <Stack spacing={0} sx={{ pt: 5, pl: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: {xs:'15px', md:"18px"},
                  fontWeight: 500,
                  color: "#000",
                  fontFamily: "Inter",
                }}
              >
                Choose categories
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: "13px", fontFamily: "Inter", }}>
                Select a category that best fits your community.
              </Typography>
              <Grid container direction="row" sx={{ width: "100%" }}>
                <Grid item xl={9}>
                  {categories.list.map((categoryName: ICategory) => (
                    <Button
                      key={categoryName.name}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                          lg: "12px",
                          xl: "15px",
                        },
                        fontFamily: "Inter",
                        fontWeight: selectedCategory === categoryName.name ? "600" : "400",
                        p: "7px 20px",
                        border:
                          selectedCategory === categoryName.name
                            ? isExploreCreateCommunityPage
                              ? " 1px solid #50A1CA"
                              : "1px solid #3760A9"
                            : "1px solid #E0E0E0",
                        borderRadius: "10px",
                        color:
                          selectedCategory === categoryName.name
                            ? isExploreCreateCommunityPage
                              ? "#50A1CA"
                              : "#3760A9"
                            : "#565656",
                        backgroundColor:
                          selectedCategory === categoryName.name ? "#ffffff" : "#ffffff",
                        m: 1,
                        ml: 0,
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleCategorySelection(categoryName.name);
                      }}
                    >
                      {categoryName.name}
                    </Button>
                  ))}
                   {open && (
                    <TextField
                      autoFocus
                      sx={{
                        mt: 1,
                        color:'#3760A9',
                        fontWeight:'600',
                        backgroundColor: "white",
                        '&..css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root':{
                          borderRadius: "10px !important",
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#3760A9', // Change the focused border color as needed
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#3760A9', // Change the border color as needed
                          },
                          '&:hover fieldset': {
                            borderColor: '#3760A9', // Change the border color on hover as needed
                          },
                        },
                      }}
                      placeholder="Type your category..."
                      InputLabelProps={{ shrink: false }}
                      inputProps={{
                        style: {
                          padding: 11,
                          borderRadius: "30px !important",
                          fontSize: "15px",
                          color:'#3760A9',
                          fontWeight:600,
                        },
                      }}
                      size="small"
                      // label="Other Category"
                      variant="outlined"
                      value={CommunityData.otherCategory}
                      onChange={(e) => {
                        //  setSelectedCategory(e.target.value);
                        const temp = {
                          ...CommunityData,
                          otherCategory: e.target.value,
                        };
                        setCommunityData(temp);
                      }}
                    />
                  )}
                </Grid>
                {/* <Grid item xl={2} lg={2} md={2} xs={5}>
                  {open && (
                    <TextField
                      autoFocus
                      sx={{
                        mt: 1,
                        borderRadius: "10px",
                        backgroundColor: "white",
                        '&.Mui-focused fieldset': {
                          borderColor: '#3760A9', // Change the focused border color as needed
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#3760A9', // Change the border color as needed
                          },
                          '&:hover fieldset': {
                            borderColor: '#3760A9', // Change the border color on hover as needed
                          },
                        },
                      }}
                      placeholder="Type your category..."
                      InputLabelProps={{ shrink: false }}
                      inputProps={{
                        style: {
                          padding: 11,
                          borderRadius: "30px",
                          fontSize: "13px",
                        },
                      }}
                      size="small"
                      // label="Other Category"
                      variant="outlined"
                      value={CommunityData.otherCategory}
                      onChange={(e) => {
                        //  setSelectedCategory(e.target.value);
                        const temp = {
                          ...CommunityData,
                          otherCategory: e.target.value,
                        };
                        setCommunityData(temp);
                      }}
                    />
                  )}
                </Grid> */}
              </Grid>

              <Box>
                <Link
                  component="button"
                  variant="body2"
                  sx={{ display: "flex", pt: 3 }}
                  onClick={() => {
                    // console.info("I'm a button.");
                  }}
                ></Link>
              </Box>

              <Box sx={{ pt: 5 }}>
                <Button
                  size="medium"
                  onClick={() => setPage(2)}
                  variant="contained"
                  disabled={!isSaveButtonEnabled()}
                  sx={{
                    boxShadow:'none',
                    background: isSaveButtonEnabled()
                    
                      ? isExploreCreateCommunityPage
                        ? "linear-gradient(to right bottom, #50a1ca, #3b9b7f)"
                        : "linear-gradient(to right bottom, #3C5AA0, #50A1CA)"
                      : "white",
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    width: "170px",
                    fontFamily: "Inter",
                    ml: 1,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#50A1CA", // Background color on hover
                      cursor: "pointer",
                      border: "none",
                    },
                  }}
                >
                  Save & Continue
                </Button>
              </Box>
            </Stack>
          </Paper>
        </>
      )}
      {page === 2 && (
        <CreateCommunityPage2
          CommunityData={CommunityData}
          updateCommunityData={updateCommunityData}
          setCommunityData={setCommunityData}
        />
      )}
    </Stack>
  );
};
