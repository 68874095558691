import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";
import { AuthContext } from "../../../../contexts/Auth.context";
import { styles } from "./Sidebar.styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import {
  BoxTickIcon,
  Setting2Icon,
} from "./../../../../assets/icons";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';

const getIcon = (name: string) => {
  switch (name) {
    case "HomeIcon":
      return <HomeRoundedIcon />;
    case "CardTickIcon":
      return <PaymentsRoundedIcon />;
    case "IcDashboardIcon":
      return <AccountBalanceWalletRoundedIcon />;
    case "PeopleIcon":
      return <PeopleRoundedIcon />;
    case "BoxTickIcon":
      return <BoxTickIcon />;
    case "SmsTrackingIcon":
      return <AssignmentRoundedIcon />;
    case "CommunityIcon":
      return <Diversity2RoundedIcon />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon />;
    case "Setting2Icon":
      return <Setting2Icon />;
    case "LogoutIcon":
      return <LogoutIcon />;
    case "AboutIcon":
      return <InfoRoundedIcon />;
    case "ExploreIcon":
      return <FormatIndentDecreaseRoundedIcon />;
    case "QuizOutlinedIcon":
      return <QuizOutlinedIcon />;
      case "ReportsIcon":
        return <AssessmentRoundedIcon />;
    case "CourseIcon":
      return <AutoStoriesRoundedIcon />;
    default:
      return null;
  }
};

// Menu item interface
interface IMenuItem {
  name: string;
  icon: string;
  url: string;
  type?: string;
  children?: IMenuItem[];
}

interface ISidebar {
  open: boolean;
}

export default function Sidebar({ open }: ISidebar) {
  const { logout } = useContext(AuthContext);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const community_id = selectedCommunity?._id;

  const handleMenuClick = (menu: IMenuItem) => {
    if (menu.url === "logout") {
      logout();
    } else {
      const isExpanded = expandedItems.includes(menu.name);
      const newExpandedItems = isExpanded
        ? expandedItems.filter((item) => item !== menu.name)
        : [...expandedItems, menu.name];
      setExpandedItems(newExpandedItems);
    }
  };

  const menusList: IMenuItem[][] = [
    [
      {
        name: "Home",
        icon: "HomeIcon",
        url: "/home",
        children: [],
      },
      {
        name: "Manage Posts",
        icon: "SmsTrackingIcon",
        url: "/posts",
      },
      {
        name: "Manage Members",
        icon: "PeopleIcon",
        url: "/users",
        children: [],
      },
      {
        name: "Manage Payments",
        icon: "CardTickIcon",
        url: "/payments",
        children: [],
      },
      {
        name: "Reports",
        icon: "ReportsIcon",
        url: "/payment-reports",
        children: [
          {
            name: "Subscription Reports",
            icon: "ReportsIcon",
            url: "/payment-reports",
          },
        ],
      },

      {
        name: "Manage Plans",
        icon: "IcDashboardIcon",
        url: "/plans",
        children: [
          {
            name: "Plans",
            icon: "IcDashboardIcon",
            url: "/plans",
          },
          {
            name: "Create Plan",
            icon: "IcDashboardIcon",
            url: "/create-plan",
          },
          {
            name: "Add Subscriber",
            icon: "IcDashboardIcon",
            url: "/create-subscriptions",
          },
          {
            name: "Subscribers",
            icon: "IcDashboardIcon",
            url: "/subscribers",
          },
        ],
      },
      {
        name: "Manage Courses",
        icon: "CourseIcon",
        url: "/courses",
      },
      {
        name: "Communities",
        icon: "CommunityIcon",
        url: "/communities",
        children: [],
      },
      {
        name: "About Community ",
        icon: "AboutIcon",
        url: `/community/${community_id}/about`,
        children: [],
      },
      {
        name: "Explore",
        icon: "ExploreIcon",
        url: "/explore-communities",
        children: [],
      },
    ],
  ];

  const menuItem = (menu: IMenuItem) => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="menu-item"
    >
      <Box display="flex" alignItems="center">
        {getIcon(menu.icon)} {open ? menu.name : ""}
      </Box>
      {menu.children &&
        menu.children.length > 0 &&
        open &&
        (expandedItems.includes(menu.name) ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        ))}
    </Stack>
  );

  return (
    <Box sx={styles}>
      <Stack spacing={open ? 1 : 0} className={open ? "expanded" : "collapsed"}>
        {menusList.map((list: IMenuItem[], i: number) => (
          <React.Fragment key={i}>
            {list.map((menu: IMenuItem, ii: number) => (
              <React.Fragment key={ii}>
                <NavLink
                  to={menu.url}
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => handleMenuClick(menu)}
                >
                  {menuItem(menu)}
                </NavLink>
                {menu.children && menu.children.length > 0 && (
                  <Collapse
                    in={expandedItems.includes(menu.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box>
                      <ul>
                        <Stack
                          sx={{
                            fontSize: "13px",
                            marginTop: "-10px",
                            ml: 1,
                            p: 1,
                            color: "#565656",
                          }}
                        >
                          {menu.children.map((subMenu: IMenuItem) => (
                            <li
                              key={subMenu.name}
                              style={{ textDecoration: "none", padding: "8px" }}
                            >
                              <NavLink
                                className={({ isActive }) =>
                                  `nav-link ${isActive ? "active" : ""}`
                                }
                                to={subMenu.url}
                              >
                                {subMenu.name}
                              </NavLink>
                            </li>
                          ))}
                        </Stack>
                      </ul>
                    </Box>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
            {menusList.length > i + 1 && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
}
