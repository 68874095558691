import axios, { AxiosError } from "axios";
import { BASE_URL_V2 } from "../configurations/url.config";
import { IAddSubscription } from "../components/Plans/Subscription/CreateSubscription";


export const createSubscription = async (
  token: string,
  id: string,
  details: IAddSubscription
) => {
  try {
    const response = await axios.post(`${BASE_URL_V2}/subscriptions/${id}/subscription`, details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (err: AxiosError | any) {
    if (err.response) {
      return {
        status: err.response.status,
        data: err.response.data,
      };
    }
    return { status: 500, data: [] };
  }
};


export const fetchSubscriptionByCommunityId = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL_V2}/subscriptions/${id}/subscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // console.log(response, "response");
    return response.data?.data;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateSubscriptionStatusBySequenceId = async (token: string, id: string, status: string) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/subscriptions/change-payment-status/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { status: 200, data: response.data };
    } else {
      return { status: response.status, data: null };
    }
  } catch (err) {
    console.error("Error in updateSubscriptionStatusBySequenceId:", err);
    return { status: 500, data: null };
  }
};


export const updateSubscriptionStatus = async (token: string, communityId: string, subscription_status: string, sequenceId: string) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/subscriptions/${communityId}/update-subscription/${sequenceId}`,
      { subscription_status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { status: 200, data: response.data };
    } else {
      return { status: response.status, data: null };
    }
  } catch (err) {
    console.error("Error in updateSubscriptionStatus:", err);
    return { status: 500, data: null };
  }
};
