import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SubNavbar } from "../Layout/components/SubNavbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { usePayment } from "../../hooks/usePayment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  TotalRevenueIcon,
  SettledBalanceIcon,
  UnSettledBalanceIcon,
} from "../../assets/icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IPaymentList } from "../../models/payment.model";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { usePlans } from "../../hooks/usePlans";
import { Reports } from "../tables/ReportsTable";
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
import { enUS } from "date-fns/locale";
import { useReports } from "../../hooks/useReports";
import { NoReports } from "../AlternatePages/NoReports";
import { IReports, } from "../../models/reports.model";

export const PaymentReports = () => {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-07"));
  const [selectedPlans, setSelectedPlans] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [paymentsList, setPaymentList] = useState<IPaymentList[]>([]);

  // console.log(paymentsList, "paymentsList");
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );

  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  const { getCommunityPaymentsById, isLoading } = usePayment();
  const { getCommunityReports } = useReports();
  const { getCommunityPlansList } = usePlans();

  const [reports, setReports] = useState<IReports[]>([]);

  console.log(reports, "reports");

  const planId = "";
  const startDate = "";
  const endDate = "";

  const reportsList = async () => {
    try {
      const response = await getCommunityReports(
        selectedCommunityId,
        planId,
        startDate,
        endDate
      );
      if (response) {
        setReports(response);
      }
    } catch (err) {
      console.log(err, "Error");
    } finally {
    }
  };

  useEffect(() => {
    reportsList();
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      const communityPayments = await getCommunityPaymentsById(
        selectedCommunityId
      );
      if (communityPayments) setPaymentList(communityPayments);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);
  const loadPlans = async () => {
    try {
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);
        if (plans_data) {
          setPlans(plans_data);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
    }
  };
  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);
  // console.log(plans)
  const handleAutocompleteChange = (_: any, newValue: any[]) => {
    setSelectedPlans(newValue);

    // Update paymentTypeFilter to include the names of the selected plans
    const updatedFilter = newValue.map(option => option.name || option.title);
    setPaymentTypeFilter(updatedFilter);
  };

  // eslint-disable-next-line
  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean;
  }>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
  });

  // eslint-disable-next-line
  const [markedAsFilter, setMarkedAsFilter] = useState<string>("");

  // Default value when neither checkbox is checked
  // eslint-disable-next-line
  const [categoryFilter, setCategoryFilter] = useState("");
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<string[]>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  //Filter Starts




  const handlePaymentStatusFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    // console.log("Checkbox value:", value); // Debugging: Check the value attribute of the checkbox
    // console.log("Checkbox checked:", checked);
    if (checked) {
      setPaymentStatusFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setPaymentStatusFilter((prevFilters) =>
        prevFilters.filter((filter) => filter !== value)
      );
    }
    // console.log("Status Filters:", paymentStatusFilter);
  };

  let totalCashAmount = 0;
  let totalOnlineAmount = 0;

  const filteredReportData = reports?.filter((item: IReports) => {
    const PaymentPlanMatch =
      paymentTypeFilter.length === 0 ||
      paymentTypeFilter.includes(item?.plan?.name);
    const PaymentStatusMatch =
      paymentStatusFilter.length === 0 ||
      paymentStatusFilter.includes(item?.paymentMode);
    const amount = item?.plan?.offerValue || item?.plan?.totalPlanValue || 0;
    if (item?.paymentMode === 'CASH') {
      totalCashAmount += amount;
    } else if (item?.paymentMode === 'ONLINE') {
      totalOnlineAmount += amount;
    }
    return PaymentPlanMatch && PaymentStatusMatch;
  });


  // console.log("Total Cash Collect Amount:", totalCashAmount);
  // console.log("Total Online Collect Amount:", totalOnlineAmount);
  console.log(filteredReportData, "filteredReported")





  const totalFinalAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.finalAmount || "0");
    return acc + finalAmount;
  }, 0);


  const receivedAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.receivedAmount || "0");
    return acc + finalAmount;
  }, 0);


  const notReceivedAmount = filteredReportData?.reduce((acc, item) => {
    const finalAmount = parseFloat(item?.notReceivedAmount || "0");
    return acc + finalAmount;
  }, 0);


  const handleClearButtonClick = () => {
    setMarkedAsFilter("");
    setCategoryFilter("");
    setPaymentTypeFilter([]);
    setPaymentStatusFilter([]);
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
    });
  };

  const [open, setOpen] = useState(false);
  const [openxs, setOpenxs] = useState(false);

  const handleDoneButtonClick = () => {
    setOpen(false);
  };

  const totalrevenue = `
   The combined amount of settled balance and unsettled balance since the beginning.
 `;

  const settledamount = `
 The platform fee amount, accumulated from all transactions made by paid community users, that has been credited to your account.
`;

  const unsettledamount = `
It represents the amount that has been received but is still pending to be credited to your account
`;

  const [showPicker, setShowPicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  // console.log(selectionRange, "selectionRange");
  const handleSelect = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    if (startDate <= endDate) {
      setSelectionRange({ ...selectionRange, startDate, endDate });
    } else {
      console.error(
        "Invalid date range: startDate must be earlier than endDate"
      );
    }
  };

  const handleInputClick = () => {
    setShowPicker(true);
  };

  return (
    <>
      <Stack direction={"column"} spacing={1} sx={{ height: "100%" }}>
        {/* for large screen  */}
        <Stack
          direction="row"
          display={{
            xs: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
            sm: "flex",
          }}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#3760A9",
                  fontFamily: "Inter",
                }}
              >
                Payments Report
              </Typography>
            </Grid>
          </Stack>

          <Stack direction={"row-reverse"} flexGrow={1} spacing={2}>
            <Box component="form" noValidate autoComplete="off">
              <FormControl size="small">
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {

                      },
                    },
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  sx={{
                    backgroundColor: "white",
                    // height:'5vh',
                    borderRadius: "30px",
                    "& .MuiSelect-icon": {
                      color: "#1A1A1A",
                    },
                    //  fontSize: "11px" ,font:'Roboto'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                      <Typography> Filters</Typography>
                    </InputAdornment>
                  }
                >


                  <Accordion disableGutters={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                        Payment Mode
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "0px" }}>
                      <Stack>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes("CASH")}
                              onChange={handlePaymentStatusFilterChange}
                              value="CASH"
                              size="small"
                            />
                          }
                          label="Cash"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes("ONLINE")}
                              onChange={handlePaymentStatusFilterChange}
                              value="ONLINE"
                              size="small"
                            />
                          }
                          label="Online"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          control={
                            <Checkbox
                              defaultChecked
                              checked={paymentStatusFilter?.includes("NOT_STARTED")}
                              onChange={handlePaymentStatusFilterChange}
                              value="NOT_STARTED"
                              size="small"
                            />
                          }
                          label="Not Started"
                        />

                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {open && (
                    <Stack direction={"row-reverse"}>
                      <Button
                        onClick={handleClearButtonClick}
                        variant="outlined"
                        size="small"
                        sx={{
                          m: 1,
                          color: "#3C5AA0",
                          textTransform: "capitalize",
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleDoneButtonClick}
                        size="small"
                        sx={{
                          m: 1,
                          backgroundColor: "#2952A2",
                          textTransform: "capitalize",
                        }}
                      >
                        Done
                      </Button>
                    </Stack>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Autocomplete
              sx={{
                width: "16vw",
                backgroundColor: "white",
                borderRadius: "30px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "30px",
                },
                "& .MuiChip-root": {
                  borderRadius: "30px",
                },
              }}
              size="small"
              limitTags={1}
              ListboxProps={{
                sx: { fontSize: "13px", font: "Roboto", borderRadius: "30px" },
              }}
              id="community-autocomplete"
              options={plans ?? []}
              getOptionLabel={(option) =>
                `${option?.title || option?.name} +${option?.counter}`
              }
              onChange={handleAutocompleteChange}
              multiple
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 4, padding: "3px", lineHeight: "1" }}
                    checked={selected}
                    size="small"
                  />
                  {option?.title || option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Plan"
                  placeholder="Type and search Plan"
                  sx={{ width: "100%", borderRadius: "30px" }}
                />
              )}
              renderTags={(value, getTagProps) => (
                <>
                  {value?.slice(0, 1).map((option, index) => (
                    <Chip
                      label={(option?.title || option?.name).slice(0, 6)}
                      {...getTagProps({ index })}
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  ))}
                  {value?.length > 1 && (
                    <Chip
                      label={`+${value?.length - 1}`}
                      disabled
                      sx={{ fontSize: "10px", height: "3vh" }}
                    />
                  )}
                </>
              )}
            />

            <Box>
              {/* <TextField
                size="small"
                label="Select Date Range"
                onClick={handleInputClick}
                fullWidth
                sx={{ backgroundColor: "white", borderRadius: "30px" }}
              /> */}
              {/* {showPicker && (
                <Box mt={2}>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    locale={enUS}
                  />
                </Box>
              )} */}
            </Box>

          </Stack>
        </Stack>

        {/* for small screen */}
        <Stack
          direction="row"
          display={{
            xs: "flex",
            md: "none",
            lg: "none",
            xl: "none",
            sm: "none",
          }}
        >
          <Stack direction={"row"} flexGrow={1} spacing={1}>
            <Grid
              container
              spacing={1}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#3760A9",
                    fontFamily: "Inter",
                  }}
                >
                  Payments Report
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" sx={{ justifyContent: "end" }}>
                  <Select
                    open={openxs}
                    onOpen={() => setOpenxs(true)}
                    onClose={() => setOpenxs(false)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      ml: 12.5,
                      "& .MuiSelect-icon": {
                        color: "#1A1A1A",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon />
                      </InputAdornment>
                    }
                  >

                    <Accordion disableGutters={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontSize: "13px", font: "Roboto" }}>
                          Payment Mode
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: "0px" }}>
                        <Stack>
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes("CASH")}
                                onChange={handlePaymentStatusFilterChange}
                                value="CASH"
                                size="small"
                              />
                            }
                            label="Cash"
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes("ONLINE")}
                                onChange={handlePaymentStatusFilterChange}
                                value="ONLINE"
                                size="small"
                              />
                            }
                            label="Online"
                          />
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            control={
                              <Checkbox
                                defaultChecked
                                checked={paymentStatusFilter?.includes("NOT_STARTED")}
                                onChange={handlePaymentStatusFilterChange}
                                value="NOT_STARTED"
                                size="small"
                              />
                            }
                            label="Not Started"
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    {openxs && (
                      <Stack
                        direction={"row-reverse"}
                        justifyContent={"space-evenly"}
                      >
                        <Button
                          onClick={handleClearButtonClick}
                          variant="outlined"
                          size="small"
                          sx={{
                            m: 1,
                            color: "#3C5AA0",
                            textTransform: "capitalize",
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleDoneButtonClick}
                          size="small"
                          sx={{
                            m: 1,
                            backgroundColor: "#2952A2",
                            textTransform: "capitalize",
                          }}
                        >
                          Done
                        </Button>
                      </Stack>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
      {/* Reports Boxes */}

      <Stack>
        <Grid container spacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
                paddingBottom: "10px",
                height: { xs: "11vh", md: "19vh" }
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TotalRevenueIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />
                  <Tooltip
                    title={totalrevenue}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Total Payment
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    ₹{totalFinalAmount}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
                height: { xs: "11vh", md: "19vh" }
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SettledBalanceIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />

                  <Tooltip
                    title={settledamount}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Paid
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    ₹{receivedAmount}
                  </Typography>
                  <Stack display={"flex"} flexDirection={"column"}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "8px", md: "13px" },
                        fontWeight: "500",
                        color: "#ffffff",
                      }}
                    >
                      Cash : {"  "}{"  "} ₹ {totalCashAmount}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: "8px", md: "13px" },
                        fontWeight: "500",
                        color: "#ffffff",
                      }}
                    >
                      Online : {" "}  ₹ {totalOnlineAmount}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4} md={4} lg={4} sx={{ mt: 1 }}>
            <Card
              sx={{
                background:
                  "linear-gradient(to bottom, #365DA7, #4482BA,#549DC2)",
                p: { xs: 0, md: 1 },
                borderRadius: "10px",
                height: { xs: "11vh", md: "19vh" }
              }}
            >
              <CardContent sx={{ display: "block" }}>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <UnSettledBalanceIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "20px", md: "30px" },
                    }}
                  />

                  <Tooltip
                    title={unsettledamount}
                    placement="left-start"
                    arrow
                    sx={{ backgroundColor: "red" }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        color: "#ffffff",
                        float: "right",
                        fontSize: { xs: "20px", md: "30px" },
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Stack>

                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{
                    fontSize: { xs: "8px", md: "15px" },
                    color: "#ffffff",
                    pt: 1,
                  }}
                >
                  Unpiad
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    {notReceivedAmount}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      <Stack direction={"column"} spacing={1}>
        <SubNavbar>
          <Stack direction={"row"} spacing={5}></Stack>
        </SubNavbar>
        <Stack>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {filteredReportData?.length === 0 ? (
                <Reports alternatePage={<NoReports />} data={undefined} />
              ) : (
                <>
                  <Reports alternatePage={""} data={filteredReportData} />
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
// function setPaymetCycleFilter(arg0: string) {
//   throw new Error("Function not implemented.");
// }